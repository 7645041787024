import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const passwordReset = async ({ email }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				forgotPassword(
					email: "${email}",
					account_type: "provider"
				)
			}`,
		});

		return data.forgotPassword;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const usePasswordResetMutation = () => {
	return useMutation({
		mutationKey: ['usePasswordResetMutation'],
		mutationFn: (values) => {
			return passwordReset(values);
		},
	});
};
