import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../corev2/utils';

export const StyledChatContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh; /* Full viewport height for a fixed layout */
	width: 100%;
`;

export const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid ${colors.table.border};
	position: sticky; /* Sticky header */
	top: 0;
	background: ${colors.white}; /* Ensure background matches the design */
	z-index: 10;
`;

export const StyledChatContent = styled.div`
	flex: 1; /* Takes up remaining space between header and bottom bar */
	box-sizing: border-box;
	margin-bottom: 65px;
	overflow-y: auto; /* Enables vertical scrolling */
	padding: 20px;
	background: ${colors.background}; /* Optional, set the chat content background */
`;

export const StyledBottomBar = styled.div`
	border-top: 1px solid ${colors.table.border};
	box-sizing: border-box;
	padding: 16px;
	position: sticky; /* Sticky bottom bar */
	bottom: 0;
	background: ${colors.white}; /* Ensure background matches the design */
	z-index: 10;
`;

export const StyledHeaderTitle = styled.h1`
	color: ${colors.primary.accent};
	font-size: 18px;
	font-weight: 500;
`;

export const StyledDateDivider = styled.div`
	text-align: left;
	color: ${colors.black};
	font-size: 14px;
	margin: 24px 0 16px;
`;

export const StyledMessage = styled.div`
	max-width: 80%;
	margin: 8px 0;
	padding: 12px 16px;
	border-radius: 12px;
	margin-bottom: 12px;
	color: black;

	${(props) =>
		props.$sent
			? `
      background: ${colors.white};
      margin-left: auto;
    `
			: `
      background: ${colors.white};
      border: 1px solid ${colors.table.border};
      margin-right: auto;
    `}
`;

export const StyledMessageText = styled.p`
	margin: 0;
	color: ${colors.black};
	line-height: 1.3;
	text-align: left;
`;

export const StyledMessageTime = styled.span`
	display: block;
	text-align: right;
	font-size: 12px;
	color: ${colors.black};
	margin-top: 4px;
`;

export const StyledInputContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const StyledIconBar = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 12px;
`;

export const StyledIconButton = styled.button`
	background: none;
	border: 1px solid ${colors.borderColor};
	color: ${colors.primary.primary};
	cursor: pointer;
	padding: 12px 12px;
	border-radius: 8px;

	&:hover {
		color: ${colors.primary.accent};
	}
`;

export const StyledInput = styled.input`
	flex: 1;
	padding: 12px;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	outline: none;
	font-size: 14px;

	&::placeholder {
		color: ${colors.forms.placeholderColor};
	}
`;

export const StyledSendButton = styled.button`
	background: ${colors.white};
	color: ${colors.primary.primary};
	border: 1px solid ${colors.borderColor};
	border-radius: 8px;
	padding: 12px 12px;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		color: ${colors.primary.highlight};
	}
`;
