import React, { useState } from 'react';

import PageHeading from '../../../core/components/PageHeading';
import PasswordResetForm from '../../components/PasswordResetForm';
import Text from '../../../../../corev2/Text';

import { usePasswordResetMutation } from '../../api/mutations/usePasswordResetMutation';

import { StyledPasswordResetPage } from './styles';

const PasswordResetPage = () => {
	const [formIsSubmitted, setFormIsSubmitted] = useState(false);
	const [serverError, setServerError] = useState('');

	const passwordResetMutation = usePasswordResetMutation();

	const passwordResetHandler = async (values) => {
		try {
			setServerError('');
			const data = await passwordResetMutation.mutateAsync({
				email: values.email,
			});

			setFormIsSubmitted(true);
		} catch (error) {
			console.error('Password reset failed', error);
			setFormIsSubmitted(true);
		}
	};

	return (
		<StyledPasswordResetPage>
			<PageHeading>Reset Password</PageHeading>

			{!formIsSubmitted && (
				<PasswordResetForm
					initialValues={{ email: '' }}
					passwordResetHandler={passwordResetHandler}
					serverError={serverError}
				/>
			)}

			{formIsSubmitted && (
				<Text>
					An email with instructions on how to reset your password has been sent to your
					email address .
				</Text>
			)}
		</StyledPasswordResetPage>
	);
};

export default PasswordResetPage;
