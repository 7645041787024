import React from 'react';
import { Route, Switch } from 'react-router-dom';

import JobPostsPage from '../pages/JobPostsPage';

import { jobPostsRoutes } from './constants';

const JobPostsRoutes = () => {
	return (
		<Switch>
			<Route path={jobPostsRoutes.home} exact>
				<JobPostsPage />
			</Route>
		</Switch>
	);
};

export default JobPostsRoutes;
