import moment from 'moment';

/**
 * Generates shifts based on the given schedule object.
 * @param {Object} schedule - The schedule object containing shift details.
 * @param {string} schedule.startDate - The start date (YYYY-MM-DD).
 * @param {string} schedule.startTime - The start time (HH:mm:ss) in local time.
 * @param {string} schedule.endDate - The end date (YYYY-MM-DD).
 * @param {string} schedule.endTime - The end time (HH:mm:ss) in local time.
 * @param {string} schedule.repeatFrequency - The repetition frequency (one_time, daily, weekly, every_weekday).
 * @param {Array<string>} schedule.daysOfWeek - The days of the week for weekly schedules (e.g., ['Mon', 'Wed', 'Fri']).
 * @returns {Array<Object>} An array of shift objects with UTC timestamps.
 */

export const generateShifts = (schedule) => {
	const shifts = [];
	const currentUTCDateTime = moment.utc(); // Current UTC datetime

	// Parse schedule details
	const { startDate, startTime, endDate, endTime, repeatFrequency, daysOfWeek = [] } = schedule;

	// Convert start and end date to moment objects (local time)
	const scheduleStartDate = moment(startDate, 'YYYY-MM-DD').startOf('day');
	const scheduleEndDate = endDate
		? moment(endDate, 'YYYY-MM-DD').endOf('day')
		: moment().add(1, 'year').endOf('day'); // Default to 1 year if no end date

	// Function to add a shift, converting from local time to UTC
	const addShift = (date) => {
		// Create local time moment objects
		const startLocalTime = moment(`${date.format('YYYY-MM-DD')}T${startTime}`);
		const endLocalTime = moment(`${date.format('YYYY-MM-DD')}T${endTime}`);

		// Convert to UTC
		const startUTC = startLocalTime.utc();
		const endUTC = endLocalTime.utc();

		// Only add future shifts
		if (startUTC.isAfter(currentUTCDateTime)) {
			shifts.push({
				start_time: startUTC.format('YYYY-MM-DD HH:mm:ss'), // MySQL UTC format
				end_time: endUTC.format('YYYY-MM-DD HH:mm:ss'), // MySQL UTC format
			});
		}
	};

	// Generate shifts based on repeatFrequency
	if (repeatFrequency === 'one_time') {
		const oneTimeDate = moment(`${startDate}T${startTime}`);
		if (oneTimeDate.isBetween(scheduleStartDate, scheduleEndDate, 'day', '[]')) {
			addShift(oneTimeDate);
		}
	} else if (repeatFrequency === 'daily') {
		for (
			let date = scheduleStartDate.clone();
			date.isSameOrBefore(scheduleEndDate);
			date.add(1, 'days')
		) {
			addShift(date);
		}
	} else if (repeatFrequency === 'weekly') {
		for (
			let date = scheduleStartDate.clone();
			date.isSameOrBefore(scheduleEndDate);
			date.add(1, 'days')
		) {
			if (daysOfWeek.includes(date.format('ddd'))) {
				addShift(date);
			}
		}
	} else if (repeatFrequency === 'every_weekday') {
		for (
			let date = scheduleStartDate.clone();
			date.isSameOrBefore(scheduleEndDate);
			date.add(1, 'days')
		) {
			if (date.isoWeekday() >= 1 && date.isoWeekday() <= 5) {
				addShift(date);
			}
		}
	}

	return shifts;
};
