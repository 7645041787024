import styled from 'styled-components';

import { colors } from '../utils';

export const StyledBackButton = styled.button`
	background: none;
	border: none;
	color: ${colors.primary.accent};
	cursor: pointer;
	padding: 8px;
	margin-right: 16px;

	display: flex;
	align-items: center;
	gap: 10px;

	&:hover {
		color: ${colors.primary.highlight};
	}
`;
