import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { OFFER_MODEL } from '../../../core/api/models';

const fetchCarePlanOffers = async ({ jobPostId }) => {
	const query = jobPostId
		? `{fetchCarePlanOffers(job_post_id: ${jobPostId}) {${OFFER_MODEL}}}`
		: `{fetchCarePlanOffers {${OFFER_MODEL}}}`;

	try {
		const data = await api.graph({
			query,
		});

		return data.fetchCarePlanOffers;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCarePlanOffersQuery = (jobPostId) => {
	return useQuery({
		queryKey: ['fetchCarePlanOffers', jobPostId],
		queryFn: () => {
			return fetchCarePlanOffers({ jobPostId });
		},
	});
};
