import React from 'react';
import moment from 'moment';

import { DangerButton, TransparentButton } from '../../../../../corev2/Buttons';
import Text from '../../../../../corev2/Text';

import Modal from '../../../core/components/Modal';
import ShiftItem from '../../../core/components/ShiftItem';

import { StyledFormContainer, StyledFormTitle, StyledButtonsContainer } from './styles';

const ReassignShiftForm = (props) => {
	const { shift, carePro, reassignShiftHandler, reassignShiftModalHandler } = props;

	return (
		<Modal>
			<StyledFormContainer>
				<StyledFormTitle>Reassign Shift</StyledFormTitle>

				<ShiftItem shift={shift} />

				<Text size='extraLarge'>
					This shift was canceled on{' '}
					{moment(shift.canceled_at).format('MMMM D, YYYY [at] h:mm A')}. Click to make
					this available to other Care Pros.
				</Text>

				<StyledButtonsContainer>
					<DangerButton size='small' onClick={() => reassignShiftHandler(shift.id)}>
						Make Available
					</DangerButton>

					<TransparentButton size='small' onClick={reassignShiftModalHandler}>
						Close
					</TransparentButton>
				</StyledButtonsContainer>
			</StyledFormContainer>
		</Modal>
	);
};

export default ReassignShiftForm;
