import React from 'react';

import Rating from '../../Rating';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledLocation,
	StyledDescriptionContainer,
	StyledGender,
	StyledDescription,
	StyledButtonContainer,
	StyledButton,
} from '../styles';

import { capitalizeFirstLetter } from 'scripts';

const OfferTile = (props) => {
	const {
		history,
		id,
		name,
		background_check_status,
		distanceToUser,
		averageReview,
		first_name,
		last_name,

		gender,
		care_type,
		image_url,
		display_name,
		professional_statement,
		buttonText,
		onSelectCarePro,
		hourly_rate,
		careProClickHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{`${first_name} ${last_name.slice(0, 1)}`}</StyledName>
						<Rating rating={averageReview} />
					</StyledPersonalInfo>
					<StyledRate>
						${hourly_rate / 100} <StyledHour>/ hr </StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				{/* <StyledLocation>Lake Raymondport</StyledLocation> */}
				<StyledDescriptionContainer>
					<StyledGender>
						{capitalizeFirstLetter(gender)} |{' '}
						{care_type === 'hourly' ? 'Hourly' : 'Live In'}
					</StyledGender>
					<StyledDescription>{professional_statement}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					<StyledButton onClick={() => onSelectCarePro(props)}>{buttonText}</StyledButton>
					<StyledButton isTransparent onClick={() => careProClickHandler(id)}>
						View
					</StyledButton>
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default OfferTile;
