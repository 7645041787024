import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledOfferModalContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999 !important;
	background-color: rgba(0, 0, 0, 0.4); /* Optional: Adds a slight overlay behind the form */
`;

export const StyledOfferModalWrapper = styled.div`
	background-color: ${colors.white};
	padding: 20px;
	z-index: 999 !important;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	width: 100%;
	height: 80%;
	max-width: 500px;
	font-size: 25px;
	overflow: scroll;
`;

export const ShiftSelectorHeading = styled.div`
	font-size: 18px;
	color: ${colors.primary.primary};
	text-align: center;
	margin-bottom: 20px;
	font-weight: 700;
	letter-spacing: 2px;
`;

export const StyledDateSelector = styled.div`
	display: flex;
	gap: 1rem;

	margin-bottom: 1rem;
`;

export const StyledShiftsContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	background-color: ${colors.white};
	color: ${colors.primary.primary};

	margin-top: 1rem;

	@media ${mediaQueries.tablet} {
		max-width: 100%;
	}
`;

export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: ${colors.primary.primary};
	margin-bottom: 8px;
	letter-spacing: 2px;

	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

export const StyledShiftDetails = styled.div`
	flex: 1;
	background-color: white;
	padding: 30px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;

	margin-top: 1rem;
	margin-right: 20px;
	border-radius: 10px;
	text-align: left;
`;

export const StyledShiftText = styled.p`
	font-size: 14px;
	color: ${colors.primary.primary};
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	gap: 1rem;
`;
