import React, { useEffect, useState } from 'react';

import api from 'scripts/api';
import { Hearts } from 'react-loader-spinner';
import Colors from 'colors';
import { toast } from 'react-toastify';

import Icon from 'AppComp/Icon';

import FormGen from '../../corev2/FormGen';
import { LoaderSection } from '../../corev2/FormGen/styles';

import DescriptivePage from '../../corev2/DescriptivePage';

import { FORM_MODEL } from '../../../reducers/graphqlModelTypes';
import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import Text from '../../corev2/Text';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';

const RegistryFormDataHandler = (props) => {
	const { history, location } = props;

	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const formId = location.pathname.split('/').pop();

		// ensure the payment_id is a number
		if (isNaN(formId)) {
			toast.error('Invalid form Data id id', TOAST_ERROR_CONFIG);
		} else {
			handleFetchFormData(formId);
		}
	}, []);

	const handleFetchFormData = async (id) => {
		try {
			const formDataRes = await api.graph({
				query: `{
          fetchRegistryFormData(
            form_data_id: ${id}
          ) {
            ${FORM_MODEL}
          }
        }`,
			});

			setFormData(formDataRes.fetchRegistryFormData);
			console.log('formDataRes', formDataRes);
			setIsLoading(false);
		} catch (err) {
			console.error(err);
		}
	};

	const updateRegistryFormData = async ({ answers }) => {
		try {
			const formId = location.pathname.split('/').pop();
			const data = await api.graph({
				query: `mutation {
          updateRegistryFormDataAnswers ( 
            form_data_id: ${formId}, 
            form_answers: ${api.graphStringify(answers)}
          ) {
            ${FORM_MODEL}
          }
        }`,
			});

			return data.updateRegistryFormDataAnswers;
		} catch (err) {
			console.error(err);
			throw new Error(err);
		}
	};

	const handleSubmit = async (values) => {
		console.log('submitting form', values);
		// do whatever you need to move forward

		// you can assume that this can be called after
		try {
			const updateVal = await updateRegistryFormData({
				answers: values,
			});
			toast.success('Form Updated', TOAST_SUCCESS_CONFIG);
		} catch (err) {
			console.error(err);
		}
	};

	if (isLoading && !formData) {
		return <p>Loading....</p>;
	}

	const getDescriptionText = () => {
		return <Text size='large'>{formData.trait_template.description}</Text>;
	};

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
				position: 'relative',
			}}
		>
			<DescriptivePage
				heading={formData.trait_template.template_label}
				descriptionContent={getDescriptionText()}
			>
				<div
					style={{
						position: 'absolute',
						top: '40px',
						left: '20px',
						cursor: 'pointer',
					}}
				>
					<Icon size='xl' icon={faChevronLeft} onClick={() => history.goBack()} />
				</div>
				<FormGen showTitle={false} formData={formData} handleSubmit={handleSubmit} />
			</DescriptivePage>
		</ParentContainer>
	);
};

export default RegistryFormDataHandler;
