import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
	StyledOfferAction,
	StyledActionDetails,
	StyledOfferActionTitle,
	StyledOfferActionDescription,
} from './styles';

const OfferAction = (props) => {
	const { title, description, goToPage } = props;

	return (
		<StyledOfferAction onClick={goToPage}>
			<StyledActionDetails>
				<StyledOfferActionTitle>{title}</StyledOfferActionTitle>
				<StyledOfferActionDescription>{description}</StyledOfferActionDescription>
			</StyledActionDetails>

			<FontAwesomeIcon size='lg' icon={faChevronRight} />
		</StyledOfferAction>
	);
};

export default OfferAction;
