import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledTileContainer = styled.div`
	width: 100%;
	padding: 30px;
	background-color: white;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	float: left;
	margin-right: 16px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	opacity: ${(props) => (props.isValid ? 1 : 0.5)};
`;

export const StyledAvatarContainer = styled.div`
	width: 100px;
	height: 100px;
	min-width: 60px;
	border-radius: 50%;
	background-color: white;
	border: 1px solid ${colors.primary.primary};
	color: ${colors.primary.primary};
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledPersonalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	padding-left: 8px;
	text-align: left;
`;

export const StyledPersonalInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
`;

export const StyledPersonalInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.25rem;
`;

export const StyledName = styled.span`
	font-size: 1.1rem;
	font-weight: 700;
	color: ${colors.primary.primary};
	margin-bottom: 0.5rem;
`;

export const StyledRate = styled.span`
	color: ${colors.primary.primary};
	font-size: 1.5rem;
	font-weight: 700;
`;

export const StyledHour = styled.span`
	font-size: 1.1rem;
`;

export const StyledLocation = styled.span`
	font-size: 0.8rem;
	color: ${colors.primary.primary};
	margin-bottom: 0.5rem;
`;

export const StyledRatingContainer = styled.div`
	margin-bottom: 0.5rem;
`;

export const StyledGender = styled.span`
	font-size: 0.75rem;
	color: ${colors.primary.primary};
`;

export const StyledDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0;
	font-size: 0.8rem;
	word-wrap: break-word;
`;

export const StyledDescription = styled.p`
	color: ${colors.primary.primary};
`;

export const StyledButtonContainer = styled.div`
	margin-top: 1rem;

	display: flex;
	gap: 10px;
`;
