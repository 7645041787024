import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const createJobPost = async ({
	title,
	description,
	jobPostStartTime,
	jobPostEndTime,
	carePlanId,
	registryId,
}) => {
	try {
		console.log(jobPostStartTime, jobPostEndTime);
		const data = await api.graph({
			query: `mutation {
					createJobPost(
						title: "${title}",
						description: "${description}",
						start_time: "${jobPostStartTime}",
						end_time: "${jobPostEndTime}",
						care_plan_id: ${carePlanId},
						registry_id: ${registryId}
					) {
					${JOB_POST_MODEL}
					}
				}`,
		});

		return data.createJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateJobPostMutation = () => {
	return useMutation({
		mutationKey: ['createJobPost'],
		mutationFn: (values) => {
			return createJobPost(values);
		},
	});
};
