import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/';
import moment from 'moment';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import Loader from '../../../../../corev2/Loader';

import { useStartShiftMutation } from '../../api/mutations/useStartShiftMutation';
import { useEndShiftMutation } from '../../api/mutations/useEndShiftMutation';
import { useUpdateShiftMutation } from '../../api/mutations/useUpdateShiftMutation';
import { useSubmitShiftMutation } from '../../api/mutations/useSubmitShiftMutation';
import { useCancelShiftMutation } from '../../api/mutations/useCancelShiftMutation';

import {
	StyledShiftWrapper,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledFieldGroup,
	StyledFieldLabel,
	StyledButtonGroup,
	StyledValue,
} from './styles';

import TimerSection from '../../components/TimerSection';
import ShiftNotes from '../../components/ShiftNotes';
import ShiftSignature from '../../components/ShiftSignature';

import { shiftsRoutes } from '../../routes/constants';

import ShiftProvider, { useShiftContext } from '../../context/ShiftContext';

const ShiftPageContent = (props) => {
	const [loadingClock, setLoadingClock] = useState(false);
	const history = useHistory();

	const { shift, isLoadingShift, refreshShift } = useShiftContext();

	const startShift = useStartShiftMutation();
	const endShift = useEndShiftMutation();
	const updateShift = useUpdateShiftMutation();
	const submitShif = useSubmitShiftMutation();
	const cancelShift = useCancelShiftMutation();

	const submitShiftHandler = async () => {
		try {
			await submitShif.mutateAsync({
				shiftId: shift.id,
			});

			history.push(shiftsRoutes.home);
		} catch (error) {
			console.error('Submit shift failed', error);
		}
	};

	const cancelShiftHandler = async () => {
		try {
			await cancelShift.mutateAsync({
				shiftId: shift.id,
			});

			history.push(shiftsRoutes.home);
		} catch (error) {
			console.error('Submit shift failed', error);
		}
	};

	const handleClockInShift = async (lat, long) => {
		try {
			await startShift.mutateAsync({
				shiftId: shift.id,
				lat: lat,
				long: long,
			});

			refreshShift();
		} catch (error) {
			console.error('Clock in failed', error);
		}
	};

	const handleClockOutShift = async (lat, long) => {
		try {
			await endShift.mutateAsync({
				shiftId: shift.id,
				lat: lat,
				long: long,
			});

			refreshShift();
		} catch (error) {
			console.error('Clock out failed', error);
		}
	};

	const handleUpdateShiftData = async (params) => {
		try {
			const updateRes = await updateShift.mutateAsync({
				shift_id: shift.id,
				params,
			});

			if (params.client_signature_url) {
				await submitShiftHandler();
			}
		} catch (error) {
			console.error('Update shift failed', error);
		}
	};

	const clockInOutHanadler = (clockIn) => {
		setLoadingClock(true);
		console.log('handleLocation clock in ');

		const success = (position) => {
			console.log('success', position);

			if (clockIn) {
				handleClockInShift(position.coords.latitude, position.coords.longitude);
			} else {
				handleClockOutShift(position.coords.latitude, position.coords.longitude);
			}
		};

		const errorHandler = (error) => {
			setLoadingClock(false);
			let status = '';

			console.log('errorHandler', error);
			switch (error.code) {
				case error.PERMISSION_DENIED:
					status = 'User denied the request for Geolocation.';
					break;
				case error.POSITION_UNAVAILABLE:
					status = 'Location information is unavailable.';
					break;
				case error.TIMEOUT:
					status = 'The request to get user location timed out.';
					break;
				case error.UNKNOWN_ERROR:
					status = 'An unknown error occurred.';
					break;
			}
		};

		if (!navigator.geolocation) {
			alert('Geolocation is not supported by your browser, please contact admin for help');
		} else {
			var options = {
				enableHighAccuracy: true,
				timeout: 15000,
				maximumAge: 0,
			};

			navigator.geolocation.getCurrentPosition(success, errorHandler, options);
		}
	};

	if (isLoadingShift) {
		return <Loader />;
	}

	return (
		<>
			<StyledShiftWrapper>
				<StyledShiftContainer>
					<StyledTitle>SHIFT DETAILS</StyledTitle>
					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Status</StyledFieldLabel>
							<StyledValue>{shift.status}</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>
					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Scheduled Clock In</StyledFieldLabel>
							<StyledValue>
								{moment(shift.start_time).format('MMMM D, YYYY [at] h:mm A')}
							</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>

					<StyledShiftDetails
						style={{
							paddingTop: '15px',
							paddingBottom: '10px',
							marginBottom: '15px',
						}}
					>
						<StyledFieldGroup>
							<StyledFieldLabel>Scheduled Clock Out</StyledFieldLabel>
							<StyledValue>
								{moment(shift.end_time).format('MMMM D, YYYY [at] h:mm A')}
							</StyledValue>
						</StyledFieldGroup>
					</StyledShiftDetails>

					{shift.status === 'clocked_in' && (
						<TimerSection startDate={new Date(shift.clock_in_time)} />
					)}

					<StyledButtonGroup>
						{/* Show "Start Shift" button if shift is assigned and starts within the next 24 hours */}
						{shift.status === 'assigned' &&
							moment(shift.start_time).isBetween(
								moment(),
								moment().add(24, 'hours')
							) && (
								<PrimaryButton
									onClick={() => clockInOutHanadler(true)}
									size='small'
								>
									Start Shift
								</PrimaryButton>
							)}

						{/* Show "Cancel Shift" button if shift is assigned */}
						{shift.status === 'assigned' && (
							<TransparentButton onClick={cancelShiftHandler} size='small'>
								Cancel Shift
							</TransparentButton>
						)}

						{/* Show "Clock Out" button if shift is currently clocked in */}
						{shift.status === 'clocked_in' && (
							<PrimaryButton onClick={() => clockInOutHanadler(false)} size='small'>
								Clock Out
							</PrimaryButton>
						)}
					</StyledButtonGroup>
				</StyledShiftContainer>

				{shift.status === 'clocked_in' && shift.status !== 'clocked_out' && (
					<ShiftNotes handleUpdateShiftData={handleUpdateShiftData} />
				)}

				{shift.status === 'clocked_out' && (
					<ShiftSignature handleUpdateShiftData={handleUpdateShiftData} />
				)}
			</StyledShiftWrapper>
		</>
	);
};

const ShiftPage = () => {
	return (
		<ShiftProvider>
			<ShiftPageContent />
		</ShiftProvider>
	);
};

export default ShiftPage;
