import styled from 'styled-components';

export const StyledTabsContainer = styled.div`
	display: flex;
	border-radius: 20px;
`;

export const StyledTabItem = styled.div`
	display: flex;
	flex: 1;
	cursor: pointer;

	background: ${({ selected }) => (selected ? '#2b69a3' : 'none')};
	border-radius: 20px;

	text-align: center;
	align-items: center;
	justify-content: center;

	padding: 8px 0;
`;

export const StyledTabText = styled.p`
	font-size: 0.9rem;
	font-weight: ${({ selected }) => (selected ? '700' : '500')};
	color: ${({ selected }) => (selected ? '#fff' : '#2b69a3')};
`;
