import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';

import {
	StyledConversationItem,
	StyledAvatar,
	StyledConversationContent,
	StyledConversationHeader,
	StyledSenderName,
	StyledTimestamp,
	StyledConversationPreview,
	StyledDeleteButton,
} from './styles';

import { truncateText } from '../../../core/lib';
import { formatTimestamp } from '../../lib';

const ConversationItem = (props) => {
	const { conversation, chatId, isArchived, onClick, archiveChatHandler } = props;

	const [showDeleteButton, setShowDeleteButton] = useState(false);

	return (
		<StyledConversationItem
			key={conversation.id}
			onClick={() => onClick(conversation)}
			isActive={conversation.id == chatId}
			onMouseEnter={() => setShowDeleteButton(true)}
			onMouseLeave={() => setShowDeleteButton(false)}
		>
			<StyledAvatar />
			<StyledConversationContent>
				<StyledConversationHeader>
					<StyledSenderName>{conversation?.provider?.displayName}</StyledSenderName>
					<StyledTimestamp>
						{formatTimestamp(conversation.latest_message_timestamp)}
					</StyledTimestamp>
					<StyledDeleteButton
						title={isArchived ? `Unarchive` : `Archive`}
						showDeleteButton={showDeleteButton}
						onClick={(event) => {
							event.stopPropagation();
							archiveChatHandler(conversation.id, !isArchived);
						}}
					>
						<FontAwesomeIcon icon={isArchived ? faUndo : faTrash} size='sm' />
					</StyledDeleteButton>
				</StyledConversationHeader>
				<StyledConversationPreview>
					{truncateText(
						conversation.latest_message ? conversation.latest_message : '',
						160
					)}
				</StyledConversationPreview>
			</StyledConversationContent>
		</StyledConversationItem>
	);
};

export default ConversationItem;
