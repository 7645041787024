import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { searchProviders, fetchProivder } from 'reducers/admin';

import SetTable from 'AppComp/SetTable';

import ProviderSearchController from './ProviderSearchController';

import { ColumnFlex, RowFlex } from 'basicStyles';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import Colors from 'colors';

import { PROVIDER_NAV_WIDTH } from 'constants';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const ParentContainer = styled.div`
	flex: 1;

	border: 12px;

	@media ${device.laptop} {
		padding-right: calc(${PROVIDER_NAV_WIDTH} / 2);
	}
`;

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const statusFilter = ['approved'];

class ProviderSearchHandler extends ProviderSearchController {
	render() {
		return (
			<ParentContainer
				style={{
					minHeight: '100vh',
				}}
			>
				<PHWhiteCon
					style={{
						backgroundColor: 'white',
						height: 'fit-content',
					}}
				>
					<RowFlex gap='20px' fullHeight fullWidth columnMobile>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
								width: '180px',
							}}
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Filter
							</Title>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Status
								</Text>
							</ColumnFlex>
							<RowFlex
								onClick={() => this.handleChange('pending')}
								alignCenter
								gap='12px'
								padding={'0px 0px 0px 20px'}
							>
								<div
									style={{
										width: '20px',
										height: '20px',
										borderRadius: '4px',
										border: '1px solid #969696',
										backgroundColor: this.state.registryApproved
											? 'white'
											: Colors.emerald,
									}}
								/>
								<Text>Pending</Text>
							</RowFlex>
							<RowFlex
								onClick={() => this.handleChange('approved')}
								alignCenter
								gap='12px'
								padding={'0px 0px 0px 20px'}
							>
								<div
									style={{
										width: '20px',
										height: '20px',
										borderRadius: '4px',
										border: '1px solid #969696',
										backgroundColor: this.state.registryApproved
											? Colors.emerald
											: 'white',
									}}
								/>

								<Text>Approved</Text>
							</RowFlex>
						</ColumnFlex>

						<ColumnFlex fullWidth>
							<SetTable
								title='Care Pro'
								renderRows={this.renderRows}
								renderLegend={this.renderLegend}
								leftPadding='16px'
							/>
						</ColumnFlex>
					</RowFlex>
				</PHWhiteCon>
			</ParentContainer>
		);
	}
}

const mapStateToProps = ({ admin }) => {
	return {
		providers: admin.providers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		searchProviders: (input) => dispatch(searchProviders(input)),
		fetchProivder: (id) => dispatch(fetchProivder(id)),
		showInfoModel: (params) => dispattch(showInfoModel(params)),
		goToProvider: (id) => dispatch(push('/registry/carepro/' + id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearchHandler);
