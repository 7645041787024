import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { OFFER_MODEL } from '../../../core/api/models';

const offerClient = async ({ jobPostId, userId, hourlyRate, shiftIds }) => {
	try {
		const query = `mutation {
			makeOfferToClient(
				${jobPostId ? `job_post_id: ${jobPostId},` : ''}
				user_id: ${userId},
				hourly_rate: ${hourlyRate},
				shiftIds: ${api.graphStringify(shiftIds)}
			){
				${OFFER_MODEL}
			}
		}`;

		const data = await api.graph({ query });

		return data.makeOfferToClient;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useOfferClientMutation = () => {
	return useMutation({
		mutationKey: ['offerClient'],
		mutationFn: (values) => {
			return offerClient(values);
		},
	});
};
