export const PAYMENT_METHOD_MODEL = `
	id,
	last_4,
	cvc_check,
	brand,
	type,
	exp_month,
	exp_year,
	default_card,
`;

export const DOCUMENT_REQUIREMENTS = `
	id,
	registry_id,
	created_at,
	user_type,
	name,
	required,
	uploaded_by,
	requires_expiration
`;

export const DOCUMENT_MODEL = `
	id,
	user_id,
	uploaded_by,
	document_url,
	uploaded_date,
	document_name,
	status,
	notes,
	rejection_notes,
	expiration_date,
	rejected_at,
	updated_at,
	
	uploadedDocuments {
		id,
		document_url,
		uploaded_date,
		document_id,
	}

	documentRequirement {
		${DOCUMENT_REQUIREMENTS}
	}

	provider {
		id,
		display_name
	}

	carePlan {
		id,
	}
`;

export const SHIFT_MODEL = `
	id,
	status,
	clock_in_time,
	clock_out_time,
	start_time,
	end_time,
	schedule_id,
	client_signature_url,
	location_lat,
	location_long,
	clock_out_location_lat,
	clock_out_location_long,
	notes,
	care_plan_options,
	rating,
	confirmed_shift_time,
	canceled_by,
	job_id

	provider {
		id,
		image_url,
		display_name
	}
`;

export const OFFERED_SHIFTS_MODEL = `
	id,
	shift_id,
	offer_id,
	provider_id,
	registry_id
	shift {
		${SHIFT_MODEL}
	}
`;

export const OFFER_MODEL = `
	id,
	job_post_id,
	job_id,
	care_plan_id,
	user_id,
	provider_id,
	status,
	offer_made_by,
	created_at

	hourly_rate,

	user {
		id,
		name
	}

	provider {
		id,
		display_name
	}

	offered_shifts {
		${OFFERED_SHIFTS_MODEL}
	}
`;

export const SCHEDULE_MODEL = `
	id, 
	start_date,
	start_time,
	end_date, 
	end_time, 
	repeat_frequency,
	days_of_week,
	created_at,
	active
`;

export const CLIENT_MODEl = `
	id,
	email,
	name,
	phone_number,
	stripe_customer_id,
	created_timestamp,
	address,

	conversation_id,
	care_plan_id,

    carePlan {
        id,
		cc_first_name,
		cc_last_name,
		cc_phone_number,
		cr_first_name,
		cr_last_name,
		cc_relation_to_cr,
		cr_display_name,
		cr_dob,
		cr_gender,
		care_zip,
        carePlanSet {
            id
        }

        careNeedsSet {
            id
        }

		schedules {
			${SCHEDULE_MODEL}
        }

        completed_platform_onboard,
		documents_approval,
		has_set_schedule
    }

    payment_methods {
        id,
        last_4,
        cvc_check,
        brand,
        type,
        exp_month,
        exp_year,
        default_card
    }
`;

export const CARE_NEEDS_MODEL = `
    id,
    label,
    setData {
        id,
        label,
        required,
        description,
        trait_type,
        value {
            id,
            attribute_trait_id,
            attribute_set_id,
            trait_value,
            last_edited,	
        }
    }
`;

export const CARE_PRO_REVIEW_MODEL = `
	id,
	rating,
	comment,
	submission_timestamp
`;

export const CARE_PRO_MODEL = `
    id,
    displayName,
    gender,
    image_url,
    background_check_status,
    professional_statement,
    care_type,
    account_status,
    display_name,
    hourly_rate,
    live_in_rate,
    averageReview,

    careAbilitySet {
        ${CARE_NEEDS_MODEL}
    }
`;

export const PAYMENT_MODEL = `
	id,
	stripe_payment_intent_id,
	hourly_rate,
	total_time_worked,
	subtotal,
	stripe_fee,
	fyi_share,
	provider_payout,
	timestamp,
	stripe_error,
	stripe_error_code,
	stripe_error_message,
	failed_payment_attempts,
	agency_fee_hourly,
	payment_status,

	user {
		name
	},

	provider {
		display_name
	}
`;

export const JOB_MODEL = `
	id,
	hourly_amount,
	job_post_id,
	care_plan_id,
	provider_id,
	status,
	offer_status,
	job_status,
	agency_fee,
	start_time,
	end_time,
	created_at,

	shifts {
		${SHIFT_MODEL}
	}
`;

export const APPLICATION_MODEL = `
	id,
	status,
	provider_id,
	job_post_id,
	jobPost {
		id,
		budget_low,
		budget_high,
		status,
		care_plan_id,
		created_at,
	}
    provider {
        ${CARE_PRO_MODEL}
	}
`;

export const JOB_POST_MODEL = `
    id,
    title,
	start_time,
	end_time,
    description,
    budget_low,
    budget_high,
    status,
    care_plan_id,
    created_at,
    edited_filter,

    jobs {
        ${JOB_MODEL}
        provider {
            ${CARE_PRO_MODEL}
        }
    }

    applications {
        ${APPLICATION_MODEL}
        provider {
            ${CARE_PRO_MODEL}
        }
    }
`;

export const MESSAGE_MODEL = `
	id,
	sent_by,
	sent_by_admin,
	text,
	timestamp,
	device_sent_from,
	type,
	sent_by,
	email_text
`;

export const CONVERSATION_ACTION_MODEL = `
	id, 
	conversation_id,
	user_id,
	care_pro_id,
	user_type,
	seen,
	timestamp,
	archived,
`;

export const CONVERSATION_MODEL = `
	id,
	user_type,
	unread_message,
	latest_message_timestamp,
	user_id,
	provider_id,
	admin_id,
	page,
	total_pages,
	latest_message

	conversationAction {
		${CONVERSATION_ACTION_MODEL}
	}

	user {
		id
		email,
		name,
		phone_number,
		
	}
	provider {
		id
		email,	
		displayName,
		first_name,
		last_name,
	}
	admin {
		id
		email,
		name
	}

	messages {
		${MESSAGE_MODEL}
	}
`;
