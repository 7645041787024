import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from '../pages/LoginPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import OnboardingPage, {
	PersonalInfoPage,
	AccountInfoPage,
	AuthCodePage,
} from '../pages/OnboardingPage';

import { authRoutes } from './constants';

const AuthRoutes = () => {
	return (
		<Switch>
			<Route path={authRoutes.login}>
				<LoginPage />
			</Route>

			<Route path={authRoutes.passwordReset}>
				<PasswordResetPage />
			</Route>

			<Route path={authRoutes.onboarding.home}>
				<OnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.personalInfo}>
							<PersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.accountInfo}>
							<AccountInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.authCode}>
							<AuthCodePage />
						</Route>
					</Switch>
				</OnboardingPage>
			</Route>
		</Switch>
	);
};

export default AuthRoutes;
