import { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const initialStats = {
	carePlans: { title: 'Care Plans', stat: 0, isActive: false, param: 'care-plans' },
	offersReceived: {
		title: 'Offers Received',
		stat: 0,
		isActive: false,
		param: 'offers-received',
	},
	offersMade: { title: 'Offers Made', stat: 0, isActive: false, param: 'offers-made' },
	offersAccepted: {
		title: 'Accepted',
		stat: 0,
		isActive: false,
		param: 'offers-accepted',
	},
	offersRejected: {
		title: 'Rejected',
		stat: 0,
		isActive: false,
		param: 'offers-rejected',
	},
};

export const useOffersFilter = (carePlans, offers) => {
	const location = useLocation();
	const [stats, setStats] = useState(initialStats);
	const [filteredOffers, setFilteredOffers] = useState([]);
	useEffect(() => {
		if (!carePlans || !offers) return;

		const offersMade = offers.filter(
			(offer) => offer.status === 'offered' && offer.offer_made_by === 'carePro'
		);
		const offersReceived = offers.filter(
			(offer) => offer.status === 'offered' && offer.offer_made_by === 'client'
		);
		const offersAccepted = offers.filter((offer) => offer.status === 'accepted');
		const offersRejected = offers.filter((offer) => offer.status === 'rejected');

		const params = new URLSearchParams(location.search);
		const status = params.get('status') || 'care-plans';

		switch (status) {
			case 'offers-received':
				setFilteredOffers(offersReceived);
				break;
			case 'offers-made':
				setFilteredOffers(offersMade);
				break;
			case 'offers-accepted':
				setFilteredOffers(offersAccepted);
				break;
			case 'offers-rejected':
				setFilteredOffers(offersRejected);
				break;
			default:
				setFilteredOffers([]);
				break;
		}

		const updatedStats = {
			...initialStats,
			carePlans: {
				...stats.carePlans,
				stat: carePlans.length,
				isActive: status === initialStats.carePlans.param,
			},
			offersReceived: {
				...stats.offersReceived,
				stat: offersReceived.length,
				isActive: status === initialStats.offersReceived.param,
			},
			offersMade: {
				...stats.offersMade,
				stat: offersMade.length,
				isActive: status === initialStats.offersMade.param,
			},
			offersAccepted: {
				...stats.offersAccepted,
				stat: offersAccepted.length,
				isActive: status === initialStats.offersAccepted.param,
			},
			offersRejected: {
				...stats.offersRejected,
				stat: offersRejected.length,
				isActive: status === initialStats.offersRejected.param,
			},
		};

		Object.keys(updatedStats).forEach((key) => {
			updatedStats[key].isActive = updatedStats[key].param === status;
		});

		setStats(updatedStats);
	}, [location.search, carePlans, offers]);

	return { stats, filteredOffers: filteredOffers.sort((a, b) => b.id - a.id) };
};
