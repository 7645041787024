import React from 'react';

import { StyledTabsContainer, StyledTabItem, StyledTabText } from './styles';

const Tabs = (props) => {
	const { options, selectHandler } = props;

	return (
		<StyledTabsContainer>
			{Object.values(options).map((option) => (
				<StyledTabItem
					key={option.name}
					selected={option.selected}
					onClick={() => selectHandler(option.name)}
				>
					<StyledTabText selected={option.selected}>{option.name}</StyledTabText>
				</StyledTabItem>
			))}
		</StyledTabsContainer>
	);
};
export default Tabs;
