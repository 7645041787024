import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const deleteShift = async ({ shiftId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					deleteShift (shift_id: ${shiftId}) {
						${SHIFT_MODEL}
					}
				}`,
		});

		return data.deleteShift;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useDeleteShiftMutation = () => {
	return useMutation({
		mutationKey: ['deleteShift'],
		mutationFn: (values) => {
			return deleteShift(values);
		},
	});
};
