import React from 'react';
import { Route, Switch } from 'react-router-dom';

import OffersPage from '../pages/OffersPage';

import { offersRoutes } from './constants';

const OffersRoutes = () => {
	return (
		<Switch>
			<Route path={offersRoutes.home} exact>
				<OffersPage />
			</Route>
		</Switch>
	);
};

export default OffersRoutes;
