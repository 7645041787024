import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledDocumentsContainer = styled.div`
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	margin: auto;

	@media ${mediaQueries.tablet} {
		width: 550px;
	}

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

export const StyledNavContainer = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
`;

export const StyledHeadingSection = styled.div`
	width: 100%;

	@media ${mediaQueries.tablet} {
		width: 33%;
	}
`;

export const StyledDocumentsWrapper = styled.div`
	width: 100%;
	max-width: 1000px;
	box-sizing: border-box;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: ${colors.primary.primary};
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: ${colors.primary.primary};
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

export const StyledDocumentRequest = styled.div`
	background-color: white;
	text-align: left;

	display: grid;
	grid-template-columns: 1fr; /* 2 cards per row on larger screens */
	gap: 20px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr; /* 1 card per row on smaller screens */
	}
`;

export const StyledSideContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
`;
