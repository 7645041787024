import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledPageHeading = styled.h1`
	color: ${colors.primary.primary};
	font-family: 'Bellota';
	letter-spacing: 2px;
	line-height: 1.4;
	margin: 0 auto;
	font-size: 1.8em;
	text-align: left;
`;
