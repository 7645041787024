import styled from 'styled-components';

export const StyledResetPasswordLinkContainer = styled.div`
	align-self: center;
	width: fit-content;

	margin-top: 10px;
`;

export const StyledLink = styled.button`
	text-decoration: underline;
	cursor: pointer;
	background: none;
	border: none;
	padding: 0;
`;
