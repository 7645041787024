import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import { FormContainer, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import passwordResetValidationSchema from './validation';

const PasswordResetForm = (props) => {
	const { serverError, initialValues, passwordResetHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => passwordResetHandler(values),
		validationSchema: toFormikValidationSchema(passwordResetValidationSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Email </Label>

					<Input
						name='email'
						type='email'
						onChange={formik.handleChange}
						placeholder='Email'
						value={formik.values.email}
					/>

					{formik.errors.email && <ErrorMessage> {formik.errors.email} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Reset Password
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default PasswordResetForm;
