import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledConversationItem = styled.div`
	display: flex;
	gap: 16px;
	padding: 12px;
	min-height: 100px;
	max-height: 100px;
	background: ${(props) => (props.isActive ? colors.pageBackground : colors.white)};};
	cursor: pointer;
	border: 1px solid ${colors.forms.placeholderColor};
	color: ${colors.black};
	position: relative;

	&:hover {
		background: ${colors.pageBackground};
	}
`;

export const StyledAvatar = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: ${colors.table.border};
	flex-shrink: 0;
`;

export const StyledConversationContent = styled.div`
	flex: 1;
	min-width: 0;
	overflow: hidden;
`;

export const StyledConversationHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
`;

export const StyledDeleteButton = styled.button`
	position: absolute;
	right: 10px;
	bottom: 0px;
	display: ${({ showDeleteButton }) => (showDeleteButton ? 'block' : 'none')};
	cursor: pointer;
	color: ${colors.primary.primary};
	padding: 5px;
	border: none;
	background: inherit;
`;

export const StyledSenderName = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: ${colors.primary.primary};
`;

export const StyledTimestamp = styled.div`
	color: ${colors.primary.primary};
	font-size: 14px;
`;

export const StyledConversationPreview = styled.div`
	display: inline-block;
	color: ${colors.primary.primary};
	font-size: 15px;
	text-overflow: ellipsis;
	text-align: left;
	word-wrap: break-word;
	max-width: 100%;
`;
