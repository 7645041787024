import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { calendarFormValidationSchema } from './validation';

import { FieldGroup, Input, Label, Select } from '../../../../../corev2/Forms';
import { PrimaryButton, DangerButton, TransparentButton } from '../../../../../corev2/Buttons';
import { ErrorMessage } from '../../../../../corev2/Forms';
import Modal from '../../../core/components/Modal';

import { generateShifts } from '../../../core/lib/generateShifts';

import {
	StyledCalendarFormContainer,
	StyledFormTitle,
	StyledInnerCalendarFormWrapper,
	StyledWeekDays,
	StyledDayButton,
	StyledButtonContainer,
} from './styles';

const formatDateTimeLocal = (date) => moment(date).format('YYYY-MM-DDTHH:mm');

const AddScheduleForm = (props) => {
	const {
		event,
		editMode,
		closePopupHandler,
		addScheduleToCarePlanHandler,
		updateEventHandler,
		deleteEventHandler,
		serverError,
		selectedDate,
	} = props;

	// Extract schedule from event
	const schedule = event?.resource?.schedule || {};

	// Formik initialization
	const formik = useFormik({
		initialValues: {
			start:
				schedule.start_time ||
				formatDateTimeLocal(selectedDate || new Date().setHours(9, 0, 0, 0)),
			end:
				schedule.end_time ||
				formatDateTimeLocal(selectedDate || new Date().setHours(17, 0, 0, 0)),
			startDate:
				schedule.start_date ||
				(selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''),
			endDate: schedule.end_date || '',
			repeatFrequency: schedule.repeat_frequency || 'one_time',
			selectedDays: schedule.days_of_week ? schedule.days_of_week.split(',') : [],
		},
		validationSchema: toFormikValidationSchema(calendarFormValidationSchema),
		onSubmit: (values) => {
			let startDate, endDate, startTime, endTime;

			if (values.repeatFrequency === 'one_time') {
				// For one-time events
				const start = new Date(values.start);
				const end = new Date(values.end);
				startDate = moment(start).format('YYYY-MM-DD');
				endDate = moment(end).format('YYYY-MM-DD');
				startTime = moment(start).format('HH:mm:ss');
				endTime = moment(end).format('HH:mm:ss');
			} else {
				// For recurring events
				startDate = values.startDate;
				endDate = values.endDate;
				startTime = values.start;
				endTime = values.end;
			}

			if (editMode) {
				updateEventHandler(
					startDate,
					startTime,
					endDate,
					endTime,
					values.repeatFrequency,
					values.selectedDays
				);
			} else {
				const shifts = generateShifts({
					startDate,
					startTime,
					endDate,
					endTime,
					repeatFrequency: values.repeatFrequency,
					daysOfWeek: values.selectedDays,
				});

				console.log('generateShifts - shifts', shifts);

				addScheduleToCarePlanHandler(
					startDate,
					startTime,
					endDate,
					endTime,
					values.repeatFrequency,
					values.selectedDays,
					shifts
				);
			}
		},
	});

	// Toggle day selection for weekly repeat
	const toggleDaySelection = (day) => {
		const selectedDays = formik.values.selectedDays.includes(day)
			? formik.values.selectedDays.filter((d) => d !== day) // Remove day
			: [...formik.values.selectedDays, day]; // Add day
		formik.setFieldValue('selectedDays', selectedDays);
	};

	return (
		<Modal>
			<StyledCalendarFormContainer>
				<StyledFormTitle>Add Schedule</StyledFormTitle>
				<StyledInnerCalendarFormWrapper>
					{/* Repeat Frequency */}
					<FieldGroup>
						<Label>Repeat</Label>
						<Select
							name='repeatFrequency'
							value={formik.values.repeatFrequency}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							<option value='one_time'>Never</option>
							<option value='daily'>Every Day</option>
							<option value='every_weekday'>Every Week Day</option>
							<option value='weekly'>Every Week</option>
						</Select>
						{formik.touched.repeatFrequency && formik.errors.repeatFrequency && (
							<ErrorMessage>{formik.errors.repeatFrequency}</ErrorMessage>
						)}
					</FieldGroup>

					{/* Start Date and End Date */}
					{formik.values.repeatFrequency !== 'one_time' && (
						<>
							<FieldGroup>
								<Label>Start Date</Label>
								<Input
									type='date'
									name='startDate'
									value={formik.values.startDate}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									// Restrict past dates by setting the min attribute
									min={moment().format('YYYY-MM-DD')} // This ensures only future dates can be selected
								/>
								{formik.touched.startDate && formik.errors.startDate && (
									<ErrorMessage>{formik.errors.startDate}</ErrorMessage>
								)}
							</FieldGroup>

							<FieldGroup>
								<Label>End Date</Label>
								<Input
									type='date'
									name='endDate'
									value={formik.values.endDate}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									// Restrict end date to at least a week from today
									min={moment().add(1, 'week').format('YYYY-MM-DD')} // Set minimum date to one week from today
								/>
								{formik.touched.endDate && formik.errors.endDate && (
									<ErrorMessage>{formik.errors.endDate}</ErrorMessage>
								)}
							</FieldGroup>
						</>
					)}

					{/* Days of the Week */}
					{formik.values.repeatFrequency === 'weekly' && (
						<FieldGroup>
							<Label>Day of the Week</Label>
							<StyledWeekDays>
								{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
									<StyledDayButton
										key={day}
										onClick={() => toggleDaySelection(day)}
										isSelected={formik.values.selectedDays.includes(day)}
									>
										{day}
									</StyledDayButton>
								))}
							</StyledWeekDays>
							{formik.touched.selectedDays && formik.errors.selectedDays && (
								<ErrorMessage>{formik.errors.selectedDays}</ErrorMessage>
							)}
						</FieldGroup>
					)}

					{/* Start Time */}
					<FieldGroup>
						<Label>Start Time</Label>
						<Input
							type={
								formik.values.repeatFrequency === 'one_time'
									? 'datetime-local'
									: 'time'
							}
							name='start'
							value={formik.values.start}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						{formik.touched.start && formik.errors.start && (
							<ErrorMessage>{formik.errors.start}</ErrorMessage>
						)}
					</FieldGroup>

					{/* End Time */}
					<FieldGroup>
						<Label>End Time</Label>
						<Input
							type={
								formik.values.repeatFrequency === 'one_time'
									? 'datetime-local'
									: 'time'
							}
							name='end'
							value={formik.values.end}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						{formik.touched.end && formik.errors.end && (
							<ErrorMessage>{formik.errors.end}</ErrorMessage>
						)}
					</FieldGroup>

					{/* Form Actions */}
					<StyledButtonContainer>
						{serverError && <ErrorMessage>{serverError}</ErrorMessage>}

						{!editMode && (
							<PrimaryButton size='small' type='submit' onClick={formik.handleSubmit}>
								Save
							</PrimaryButton>
						)}

						{editMode && (
							<DangerButton size='small' onClick={() => deleteEventHandler(event)}>
								Delete
							</DangerButton>
						)}
						<TransparentButton size='small' onClick={closePopupHandler}>
							Cancel
						</TransparentButton>
					</StyledButtonContainer>
				</StyledInnerCalendarFormWrapper>
			</StyledCalendarFormContainer>
		</Modal>
	);
};

export default AddScheduleForm;
