import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import HomePage from '../pages/HomePage';
import TOSPage from '../pages/TOSPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';

import AuthRoutes from '../../auth/routes/';
import JobPostsRoutes from '../../jobPosts/routes';
import ProfileRoutes from '../../profiles/routes';
import ChatRoutes from '../../chat/routes';
import OfferRoutes from '../../offers/routes';
import BlockedTimesRoutes from '../../blockedTimes/routes';
import DocumentsRoutes from '../../documents/routes';
import DashboardRoutes from '../../dashboard/routes';
import PaymentsRoutes from '../../payments/routes';
import ShiftsRoutes from '../../shifts/routes/';
import Skills from '../../skills';

import { coreRoutes } from './constants';

const AppRoutes = () => {
	return (
		<Switch>
			<Route path={coreRoutes.auth}>
				<AuthRoutes />
			</Route>

			<PrivateRoute path={coreRoutes.jobPosts} component={JobPostsRoutes} />

			<PrivateRoute path={coreRoutes.offers} component={OfferRoutes} />

			<PrivateRoute path={coreRoutes.profiles} component={ProfileRoutes} />

			<PrivateRoute path={coreRoutes.chat} component={ChatRoutes} />

			<PrivateRoute path={coreRoutes.blockedTimes} component={BlockedTimesRoutes} />

			<PrivateRoute path={coreRoutes.shifts} component={ShiftsRoutes} />

			<PrivateRoute path={coreRoutes.skills} component={Skills} />

			<PrivateRoute path={coreRoutes.documents} component={DocumentsRoutes} />

			<PrivateRoute path={coreRoutes.dashboard} component={DashboardRoutes} />

			<PrivateRoute path={coreRoutes.payments} component={PaymentsRoutes} />

			<Route path={coreRoutes.jobPosts}>
				<JobPostsRoutes />
			</Route>

			<Route path={coreRoutes.tos}>
				<TOSPage />
			</Route>

			<Route path={coreRoutes.privacyPolicy}>
				<PrivacyPolicyPage />
			</Route>

			<Route path={coreRoutes.root} exact>
				<HomePage />
			</Route>
		</Switch>
	);
};

export default AppRoutes;
