import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobPost = styled.div`
	max-width: 100%;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 20px;
	background: ${colors.primary.primary};
	cursor: pointer;

	display: flex;
	gap: 12px;
	align-items: center;

	transition: transform 0.1s ease-in-out;

	&:hover {
		transform: scale(1.02);
	}
`;

export const StyledInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.25rem;
	flex-grow: 1;
`;

export const StyledStatsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.25rem;
	flex-grow: 1;
	justify-content: flex-end;
`;

export const StyledJobPostTitle = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${colors.white};
	line-height: 1.4;
	margin-bottom: 0.25rem;
`;

export const StyledJobPostDescription = styled.p`
	font-size: 14px;
	color: ${colors.white};
	line-height: 1.4;
`;

export const StyledDateRange = styled.p`
	font-size: 12px;
	color: ${colors.white};
	margin-top: 5px;
	opacity: 0.8;
`;

export const StyledStatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	margin-top: 5px;
	flex-direction: row-reverse;
`;

export const StyledOffersContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 14px;
	color: ${colors.white};
`;
