import React from 'react';

import { DangerButton, TransparentButton } from '../../../../../corev2/Buttons';
import Text from '../../../../../corev2/Text';

import Modal from '../../../core/components/Modal';
import ShiftItem from '../../../core/components/ShiftItem';

import { StyledFormContainer, StyledFormTitle, StyledButtonsContainer } from './styles';

const CancelShiftForm = (props) => {
	const { shift, carePro, cancelShiftHandler, cancelShiftModalHandler } = props;

	return (
		<Modal>
			<StyledFormContainer>
				<StyledFormTitle>Cancel Shift</StyledFormTitle>

				<ShiftItem isAvailable={true} shift={shift} />

				<Text size='extraLarge'>
					This action is irreversable. This shift has already been assigned to{' '}
					{carePro.display_name}. They will be notified of the cancellation.
				</Text>

				<StyledButtonsContainer>
					<DangerButton size='small' onClick={() => cancelShiftHandler(shift.id)}>
						Cancel Shift
					</DangerButton>

					<TransparentButton size='small' onClick={cancelShiftModalHandler}>
						Close
					</TransparentButton>
				</StyledButtonsContainer>
			</StyledFormContainer>
		</Modal>
	);
};

export default CancelShiftForm;
