import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import colors from 'colors';

import { fetchAdminConversations, adminSendMessageToConversation } from 'reducers/admin';

import PageLoaderView from '../../PageLoaderView';

import { ParentContainer } from '@appComp/provider/ProviderHomeDashboard';

import Chat from './components/Chat';
import ChatScreen from './components/ChatScreen';

const AdminChatHandler = (props) => {
	const { conversations, fetchAdminConversations, adminSendMessageToConversation } = props;

	const [selectedConversation, setSelectedConversation] = useState(null);

	useEffect(() => {
		fetchAdminConversations();
	}, [fetchAdminConversations]);

	useEffect(() => {
		if (selectedConversation) {
			const conversation = conversations.find((d) => d.id === selectedConversation.id);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [conversations, selectedConversation]);

	const handleSendMessage = async (message) => {
		if (!selectedConversation) return;
		adminSendMessageToConversation({
			conversation_id: selectedConversation.id,
			text: message,
		});
	};

	const handleChatClick = (conversation) => {
		setSelectedConversation(conversation);
	};

	return (
		<>
			{conversations.length === 0 ? (
				<PageLoaderView errorMessage='Something went wrong, please reload the page and try again' />
			) : (
				<>
					<Chat
						onClick={handleChatClick}
						conversations={conversations}
						loggedInUser='admin'
					/>

					<ChatScreen
						conversations={selectedConversation}
						handleSendMessage={handleSendMessage}
						loggedInUser='admin'
					/>
				</>
			)}
		</>
	);
};

const mapStateToProps = ({ admin }) => ({
	conversations: admin.conversations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAdminConversations: () => dispatch(fetchAdminConversations()),
	adminSendMessageToConversation: (params) => dispatch(adminSendMessageToConversation(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminChatHandler);
