export const MIN_TRAIT_TEMPLATE_MODEL = `
  id,
  template_label,
  registry_id,
  template_type,
  description,
`;

export const TRAIT_MODEL = `
  id,
  label,
  required,
  active,
  trait_type,
  description,
  meta_data,
  status,
  section_id
`;

export const TRAIT_TEMPLATE_SECTION_MODEL = `
  id,
  label,
  order_index,
  traits {
      ${TRAIT_MODEL}
  }
`;

export const TRAIT_TEMPLATE_MODEL = `
  ${MIN_TRAIT_TEMPLATE_MODEL},
  sections {
    ${TRAIT_TEMPLATE_SECTION_MODEL}
    traits {
      ${TRAIT_MODEL}
    }
  }
 
`;
