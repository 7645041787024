import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik-latest';

import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FieldGroup, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';

import ShiftItem from '../../../core/components/ShiftItem';

import { shiftSelectorValidationSchema } from './validation';

import {
	StyledShiftsSelectorContainer,
	StyledShiftsSelectorWrapper,
	StyledShiftSelectionForm,
	ShiftSelectorHeading,
	StyledDateSelector,
	StyledShiftDetails,
	StyledShiftText,
	StyledShiftsContainer,
	StyledTitle,
	StyledButtonContainer,
} from './styles';

const ShiftSelector = (props) => {
	const { title, shifts, shiftSelectionModalHandler, offerCareProHandler } = props;
	const [filteredShifts, setFilteredShifts] = useState([]);
	const [selectedShifts, setSelectedShifts] = useState([]);

	const formik = useFormik({
		initialValues: {
			hourlyRate: '',
			jobStartDate: moment().format('YYYY-MM-DD'),
			jobEndDate: moment().add(7, 'day').format('YYYY-MM-DD'),
		},
		validationSchema: toFormikValidationSchema(shiftSelectorValidationSchema),
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values) => {
			offerCareProHandler(values.hourlyRate, selectedShifts);
		},
	});

	// 🛠 UseEffect to Filter & Sort Shifts Based on Date Range
	useEffect(() => {
		if (shifts && formik.values.jobStartDate && formik.values.jobEndDate) {
			const jobStartDate = moment(formik.values.jobStartDate, 'YYYY-MM-DD').startOf('day');
			const jobEndDate = moment(formik.values.jobEndDate, 'YYYY-MM-DD').endOf('day');

			// 🔥 Transform and filter shifts
			const shiftsWithinDateRange = shifts
				.map((shift) => {
					const startDateTime = moment(shift.start_time);
					const endDateTime = moment(shift.end_time);

					return {
						id: shift.id,
						title: 'Care Session',
						status: shift.status,
						startDate: startDateTime.format('YYYY-MM-DD'),
						startTime: startDateTime.format('MMMM D, YYYY [at] h:mm A'),
						endDate: endDateTime.format('YYYY-MM-DD'),
						endTime: endDateTime.format('MMMM D, YYYY [at] h:mm A'),
					};
				})
				.filter((shift) => {
					const shiftStart = moment(shift.startDate, 'YYYY-MM-DD');
					return (
						shift.status === 'pending' &&
						shiftStart.isBetween(jobStartDate, jobEndDate, null, '[]')
					);
				})
				.sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());

			setFilteredShifts(shiftsWithinDateRange);
			setSelectedShifts(shiftsWithinDateRange.map((shift) => shift.id.toString()));

			// 🔄 Update jobStartDate to the start date of the first available shift
			if (shiftsWithinDateRange.length > 0) {
				formik.setFieldValue('jobStartDate', shiftsWithinDateRange[0].startDate);
			}
		}
	}, [shifts, formik.values.jobStartDate, formik.values.jobEndDate]);

	const toggleShiftSelection = (shiftId) => {
		setSelectedShifts((prevSelectedShifts) =>
			prevSelectedShifts.includes(shiftId)
				? prevSelectedShifts.filter((id) => id !== shiftId)
				: [...prevSelectedShifts, shiftId]
		);
	};

	return (
		<StyledShiftsSelectorContainer>
			<StyledShiftsSelectorWrapper>
				<StyledShiftSelectionForm onSubmit={formik.handleSubmit}>
					<ShiftSelectorHeading>{title}</ShiftSelectorHeading>

					<FieldGroup>
						<Label>Hourly Rate</Label>
						<Input
							type='number'
							name='hourlyRate'
							min={5}
							max={50}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.hourlyRate}
						/>

						{formik.touched.hourlyRate && formik.errors.hourlyRate && (
							<ErrorMessage>{formik.errors.hourlyRate}</ErrorMessage>
						)}
					</FieldGroup>

					<StyledDateSelector>
						<FieldGroup>
							<Label>Job Start Date</Label>
							<Input
								type='date'
								name='jobStartDate'
								min={formik.values.jobStartDate}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jobStartDate}
							/>

							{formik.touched.jobStartDate && formik.errors.jobStartDate && (
								<ErrorMessage>{formik.errors.jobStartDate}</ErrorMessage>
							)}
						</FieldGroup>

						<FieldGroup>
							<Label>Job End Date</Label>
							<Input
								type='date'
								name='jobEndDate'
								min={moment(formik.values.jobStartDate)
									.add(1, 'day')
									.format('YYYY-MM-DD')}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jobEndDate}
							/>

							{formik.touched.jobEndDate && formik.errors.jobEndDate && (
								<ErrorMessage>{formik.errors.jobEndDate}</ErrorMessage>
							)}
						</FieldGroup>
					</StyledDateSelector>

					<StyledTitle>Available Shifts</StyledTitle>
					<StyledShiftDetails>
						{filteredShifts.length > 0 ? (
							<StyledShiftText>
								These are the available shifts from your schedule. Select shifts for
								the next 30 days. Click to deselect shifts that you don't want to
								assign to this CarePro.
							</StyledShiftText>
						) : (
							<StyledShiftText>
								No upcoming shifts in the selected date range.
							</StyledShiftText>
						)}

						<StyledShiftsContainer>
							{filteredShifts.map((shift) => (
								<ShiftItem
									key={shift.id}
									shift={shift}
									isAvailable={shift.status === 'pending'}
									isSelected={selectedShifts.includes(shift.id.toString())}
									onClickHandler={() => {
										if (shift.status === 'pending') {
											toggleShiftSelection(shift.id.toString());
										}
									}}
								/>
							))}
						</StyledShiftsContainer>
					</StyledShiftDetails>

					<StyledButtonContainer>
						<PrimaryButton
							size='small'
							type='submit'
							disabled={filteredShifts.length === 0}
						>
							Confirm Selection
						</PrimaryButton>

						<TransparentButton onClick={shiftSelectionModalHandler} size='small'>
							Cancel
						</TransparentButton>
					</StyledButtonContainer>
				</StyledShiftSelectionForm>
			</StyledShiftsSelectorWrapper>
		</StyledShiftsSelectorContainer>
	);
};

export default ShiftSelector;
