import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
	StyledContainer,
	StyledSearchContainer,
	StyledSearchInput,
	StyledSearchIcon,
	StyledMessageList,
	StyledMessageItem,
	StyledAvatar,
	StyledMessageContent,
	StyledMessageHeader,
	StyledSenderName,
	StyledTimestamp,
	StyledMessagePreview,
	StyledChevronIcon,
} from './styles';

import { formatTimestamp } from '../../lib';

const Chat = (props) => {
	const { onClick, conversations = [] } = props;

	return (
		<StyledContainer>
			<StyledSearchContainer>
				<StyledSearchInput placeholder='Search Messages' />
				<StyledSearchIcon>
					<FontAwesomeIcon icon={faSearch} />
				</StyledSearchIcon>
			</StyledSearchContainer>

			<StyledMessageList>
				{conversations.length > 0 ? (
					conversations.map((message) => (
						<StyledMessageItem key={message.id} onClick={() => onClick(message)}>
							<StyledAvatar />
							<StyledMessageContent>
								<StyledMessageHeader>
									<StyledSenderName>
										{message?.provider?.displayName} & {message.user.name}
									</StyledSenderName>
									<StyledTimestamp>
										{formatTimestamp(message.latest_message_timestamp)}
									</StyledTimestamp>
								</StyledMessageHeader>
								<StyledMessagePreview>
									{message.latest_message}
								</StyledMessagePreview>
							</StyledMessageContent>
							<StyledChevronIcon>
								<FontAwesomeIcon icon={faChevronRight} />
							</StyledChevronIcon>
						</StyledMessageItem>
					))
				) : (
					<p>No conversations found</p>
				)}
			</StyledMessageList>
		</StyledContainer>
	);
};

export default Chat;
