import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledContainer = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const StyledTabsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${colors.forms.placeholderColor} 1px solid;
	padding-bottom: 8px;
`;

export const StyledHeading = styled.div`
	text-transform: uppercase;
	color: ${colors.primary.primary};
	font-size: 14px;
	letter-spacing: 2px;
`;

export const StyledSearchContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top
	margin-bottom: 20px;
	max-width: 100%;
	border: 1px solid ${colors.forms.placeholderColor};
`;

export const StyledSearchInput = styled.input`
	width: 90%;
	padding: 12px 40px 12px 16px;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	outline: none;
	box-sizing: border-box;

	&::placeholder {
		color: ${colors.forms.placeholderColor};
	}
`;

export const StyledSearchIcon = styled.div`
	position: relative;
	margin-left: 10px;
	color: ${colors.primary.primary};
	border: 1px solid ${colors.forms.placeholderColor};
	cursor: pointer;
`;

export const StyledConversationsList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	border-radius: 6px;
	overflow: hidden;
`;

export const StyledDeleteButton = styled.button`
	position: absolute;
	right: 10px;
	bottom: 0px;
	display: ${({ showDeleteButton }) => (showDeleteButton ? 'block' : 'none')};
	cursor: pointer;
	color: ${colors.primary.primary};
	padding: 5px;
	border: none;
	background: inherit;
`;

export const StyledSenderName = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: ${colors.primary.primary};
`;

export const StyledTimestamp = styled.div`
	color: ${colors.primary.primary};
	font-size: 14px;
`;

export const StyledMessagePreview = styled.div`
	display: inline-block;
	color: ${colors.primary.primary};
	font-size: 15px;
	text-overflow: ellipsis;
	text-align: left;
	word-wrap: break-word;
	max-width: 100%;
`;
