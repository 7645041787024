import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import Text from '../../Text';
import { PrimaryButton, SecondaryButton } from '../../Buttons';
import Rating from '../../Rating';

import { functions } from '../../utils';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledRatingContainer,
	StyledLocation,
	StyledGender,
	StyledDescriptionContainer,
	StyledDescription,
	StyledButtonContainer,
} from './styles';

const OfferTile = (props) => {
	const {
		id,
		client,
		carePro,
		hourly_rate,
		offer_text,
		job_post_id,
		job_id,
		care_plan_id,
		user_id,
		care_pro_id,
		status,
		offer_made_by,
		created_at,
		shifts,
		isValid,
		customButtonText,
		onCustomButtonClick,
	} = props;

	return (
		<StyledTileContainer isValid={isValid}>
			<StyledAvatarContainer>
				<FontAwesomeIcon size='2x' icon={faUser} />
			</StyledAvatarContainer>

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{client?.name || carePro?.display_name}</StyledName>
						{/* <StyledRatingContainer>
							<Rating rating={rating} />
						</StyledRatingContainer> */}
						{/* <StyledLocation>Lake Raymondport</StyledLocation> */}
						{/* <StyledGender>
							{functions.capitalizeFirstLetter(gender)} |{' '}
							{careType === 'hourly' ? 'Hourly' : 'Live In'}
						</StyledGender> */}
					</StyledPersonalInfo>
					<StyledRate>
						<StyledHour>{hourly_rate ? `$${hourly_rate} / hr` : 'No rate'}</StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				<StyledLocation>
					{
						// this should be location
					}
				</StyledLocation>
				<StyledDescriptionContainer>
					<StyledDescription>{offer_text}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					{customButtonText && (
						<PrimaryButton size='small' onClick={() => onCustomButtonClick(id)}>
							{customButtonText}
						</PrimaryButton>
					)}

					{/* <SecondaryButton size='small' onClick={() => viewCareProHandler(id)}>
						View
					</SecondaryButton> */}
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default OfferTile;
