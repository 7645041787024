import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBars,
	faImage,
	faPaperclip,
	faPaperPlane,
	faArrowUp,
} from '@fortawesome/free-solid-svg-icons';

import Text from '../../../../corev2/Text';

import {
	StyledChatContainer,
	StyledHeader,
	StyledHeaderTitle,
	StyledChatContent,
	StyledDateDivider,
	StyledMessage,
	StyledMessageText,
	StyledMessageTime,
	StyledBottomBar,
	StyledIconBar,
	StyledIconButton,
	StyledInputContainer,
	StyledInput,
	StyledSendButton,
} from './styles';

import { groupMessagesByDate } from '../../lib';

const ChatScreen = (props) => {
	const { conversations = { messages: [] }, handleSendMessage } = props;
	const history = useHistory();

	const [showIcons, setShowIcons] = useState(false);
	const [message, setMessage] = useState('');

	const groupedConversations = useMemo(
		() => groupMessagesByDate(conversations?.messages || []),
		[conversations?.messages]
	);

	const toggleIcons = useCallback(() => {
		setShowIcons((prev) => !prev);
	}, []);

	const handleInputChange = useCallback((e) => {
		setMessage(e.target.value);
	}, []);

	const handleSend = useCallback(() => {
		if (message.trim()) {
			handleSendMessage(message);
			setMessage('');
		}
	}, [message, handleSendMessage]);

	return (
		<StyledChatContainer>
			<StyledHeader>
				<StyledHeaderTitle>Chat</StyledHeaderTitle>
			</StyledHeader>

			<StyledChatContent>
				{groupedConversations.map((dateGroup, idx) => (
					<div key={idx}>
						<StyledDateDivider>{dateGroup.date}</StyledDateDivider>
						{dateGroup.messages.map((message, msgIdx) => (
							<StyledMessage key={msgIdx} $sent={message.sent_by === 'carePro'}>
								<StyledMessageText>{message.text}</StyledMessageText>
								<StyledMessageTime>
									{new Date(message.timestamp * 1000).toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
									})}
								</StyledMessageTime>
							</StyledMessage>
						))}
					</div>
				))}

				{groupedConversations.length === 0 && <Text>No messages.</Text>}
			</StyledChatContent>

			<StyledBottomBar>
				{showIcons && (
					<StyledIconBar>
						<StyledIconButton>
							<FontAwesomeIcon icon={faArrowUp} />
						</StyledIconButton>
						<StyledIconButton>
							<FontAwesomeIcon icon={faPaperclip} />
						</StyledIconButton>
						<StyledIconButton>
							<FontAwesomeIcon icon={faImage} />
						</StyledIconButton>
					</StyledIconBar>
				)}

				<StyledInputContainer>
					<StyledIconButton onClick={toggleIcons}>
						<FontAwesomeIcon icon={faBars} />
					</StyledIconButton>
					<StyledInput
						placeholder='Send a message'
						value={message}
						disabled
						onChange={handleInputChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSend();
							}
						}}
					/>
					<StyledSendButton onClick={handleSend}>
						<FontAwesomeIcon icon={faPaperPlane} />
					</StyledSendButton>
				</StyledInputContainer>
			</StyledBottomBar>
		</StyledChatContainer>
	);
};

export default ChatScreen;
