import React from 'react';
import { usePDF } from 'react-to-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import PageHeading from '../../components/PageHeading';
import PrivacyPolicy from '../../components/PrivacyPolicy';

import {
	StyledPrivacyPolicyPage,
	StyledPageHeadingContainer,
	StyledPdfContainer,
	StyledPrivacyPolicyContainer,
} from './styles';

const PrivacyPolicyPage = () => {
	const { toPDF, targetRef } = usePDF({ filename: 'FYI_PRIVACY.pdf' });

	const handlePrint = () => toPDF();

	return (
		<StyledPrivacyPolicyPage ref={targetRef}>
			<StyledPageHeadingContainer>
				<PageHeading> Privacy Notice </PageHeading>

				<StyledPdfContainer onClick={handlePrint}>
					<FontAwesomeIcon icon={faFilePdf} />
				</StyledPdfContainer>
			</StyledPageHeadingContainer>

			<StyledPrivacyPolicyContainer>
				<PrivacyPolicy />
			</StyledPrivacyPolicyContainer>
		</StyledPrivacyPolicyPage>
	);
};

export default PrivacyPolicyPage;
