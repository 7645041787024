import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import moment from 'moment';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Title from 'AppComp/Title';
import Colors from 'colors';

import Button from 'AppComp/Button';

import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import ModelOverlay from 'AppComp/ModelOverlay';
import OverlayWrapper from 'AppComp/OverlayWrapper';
import Text from 'AppComp/Text';
import SetTable from 'AppComp/SetTable';
import PageLoaderView from 'AppComp/PageLoaderView';
import AnimationWrapper from 'AppComp/AnimationWrapper';
import { faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Icon from 'AppComp/Icon';

import { renderDisputesTableLegend, SetTableContainer } from './AdminDisputesView';

import api from 'scripts/api';

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const AdminDisputesHandler = (props) => {
	const [disputes, setDisputes] = useState(null);

	const [sortByDate, setSortByDate] = useState('date'); // State for sorting by date
	const [sortOrderDate, setSortOrderDate] = useState('ascending'); // Sorting order for date
	const [sortByTime, setSortByTime] = useState('time'); // State for sorting by disputed time
	const [sortOrderTime, setSortOrderTime] = useState('ascending'); // Sorting order for disputed time

	useEffect(() => {
		handleFetchDisputes();
	}, []);

	const handleFetchDisputes = async () => {};

	const sortDisputesByDate = (disputes) => {
		return disputes.sort((a, b) => {
			// Parse dates from strings
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);

			// Handle cases where date parsing fails
			if (isNaN(dateA) || isNaN(dateB)) {
				// If parsing fails, fallback to string comparison
				return sortOrderDate === 'ascending'
					? a.date.localeCompare(b.date)
					: b.date.localeCompare(a.date);
			}

			// Compare dates
			return sortOrderDate === 'ascending' ? dateA - dateB : dateB - dateA;
		});
	};

	const sortDisputesByTime = (disputes) => {
		return disputes.sort((a, b) => {
			const timePassedA = getTimePassed(a);
			const timePassedB = getTimePassed(b);

			// Compare disputed times
			if (sortOrderTime === 'ascending') {
				return timePassedA - timePassedB;
			} else {
				return timePassedB - timePassedA;
			}
		});
	};

	const getTimePassed = (dispute) => {
		const startTime = dispute.start_time ? moment(dispute.start_time) : null;
		const endTime = dispute.end_time ? moment(dispute.end_time) : null;

		if (startTime && endTime) {
			const duration = moment.duration(endTime.diff(startTime));
			return duration.asSeconds();
		}

		return 0;
	};

	const handleSortByDate = () => {
		setSortByDate('date');
		setSortOrderDate(sortOrderDate === 'ascending' ? 'descending' : 'ascending');
		// Reset sorting order for disputed time
		setSortOrderTime('ascending');
	};

	const handleSortByTime = () => {
		setSortByTime('time');
		setSortOrderTime(sortOrderTime === 'ascending' ? 'descending' : 'ascending');
		// Reset sorting order for date
		setSortOrderDate('ascending');
	};

	const renderDateFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor: sortByDate === 'date' ? Colors.emerald : 'white',
					}}
					margin='15px 15px 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleSortByDate}
				>
					<Text
						style={{
							textAlign: 'center',
							color: sortByDate === 'date' ? 'white' : Colors.emerald,
						}}
						bold
						midLarge
					>
						{sortByDate === 'date'
							? `${sortOrderDate === 'ascending' ? 'Ascending' : 'Descending'}`
							: 'Sort by Date'}
					</Text>
					<Icon
						icon={
							sortByDate === 'date'
								? sortOrderDate === 'ascending'
									? faChevronUp
									: faChevronDown
								: null
						}
						color={
							sortByDate === 'date'
								? sortOrderDate === 'ascending'
									? 'white'
									: Colors.emerald
								: null
						}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
			</ColumnFlex>
		);
	};
	const renderTimeFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor: sortByTime === 'time' ? Colors.emerald : 'white',
					}}
					margin='15px 15px 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleSortByTime}
				>
					<Text
						style={{
							textAlign: 'center',
							color: sortByTime === 'time' ? 'white' : Colors.emerald,
						}}
						bold
						midLarge
					>
						{sortByTime === 'time'
							? `${sortOrderTime === 'ascending' ? 'Ascending' : 'Descending'}`
							: 'Sort by Time'}
					</Text>
					<Icon
						icon={
							sortByTime === 'time'
								? sortOrderTime === 'ascending'
									? faChevronUp
									: faChevronDown
								: null
						}
						color={
							sortByTime === 'time'
								? sortOrderTime === 'ascending'
									? 'white'
									: Colors.emerald
								: null
						}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
			</ColumnFlex>
		);
	};

	const renderTableRow = () => {
		if (disputes === null) {
			return <Text>Loading...</Text>;
		}
		const sortedDisputes = [...disputes];

		console.log('sortedDisputes', sortedDisputes);

		// if (sortByDate === 'date') {
		// 	sortedDisputes = sortDisputesByDate(sortedDisputes);
		// } else {
		// 	sortedDisputes = sortDisputesByTime(sortedDisputes);
		// }

		// Sort disputes based on the date
		sortedDisputes.sort((a, b) => {
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);
			return sortOrderDate === 'ascending' ? dateA - dateB : dateB - dateA;
		});

		if (!sortedDisputes || sortedDisputes.length === 0) {
			return <Text>No Disputes</Text>;
		}

		return sortedDisputes.map((d) => {
			const startTime = d.start_time ? moment(d.start_time) : null;
			const endTime = d.end_time ? moment(d.end_time) : null;
			let timePassed = null;

			if (startTime && endTime) {
				const duration = moment.duration(endTime.diff(startTime));
				const hours = Math.floor(duration.asHours());
				const minutes = Math.floor(duration.asMinutes()) - hours * 60;
				const seconds = Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;
				timePassed = `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
			}

			if (!timePassed || timePassed === '0 hours, 0 minutes, 0 seconds') {
				return null;
			}

			return (
				<SetTable.RowAction
					onClick={() => props.history.push(`/registry/dispute/${d.id}`)}
					key={'rtr1-' + d.id}
				>
					<SetTable.ColumnCon>
						<RowFlex center>
							<Text margin='0 0 0 6px' pointer underline>
								{d.provider
									? d.provider.displayName ||
									  d.provider.first_name + ' ' + d.provider.last_name
									: ''}
							</Text>
						</RowFlex>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text>{startTime ? startTime.format('MM/DD/YYYY hh:mm:ss A') : ''}</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text>{endTime ? endTime.format('MM/DD/YYYY hh:mm:ss A') : ''}</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text> {timePassed} </Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text> {d.text} </Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text> {d.dispute_cause} </Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon
						style={{
							pointer: 'cursor',
						}}
						flex='1'
					>
						<div
							style={{
								cursor: 'pointer',
							}}
						>
							<Icon icon={faChevronRight} color={Colors.emerald} size='lg' />
						</div>
					</SetTable.ColumnCon>
				</SetTable.RowAction>
			);
		});
	};

	return (
		<>
			<ParentContainer
				style={{
					minHeight: '100vh',
				}}
			>
				<PHDColumn fullHeight>
					<RowFlex gap='30px' fullHeight fullWidth columnMobile>
						<PHWhiteCon
							style={{
								flex: 1,
								backgroundColor: 'white',
								height: 'fit-content',
							}}
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Filter
							</Title>
							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.lightGrey,
									padding: '10px 10px 10px 10px',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignStart
								gap='12px'
							>
								<Text fontStyle='bold' color='#969696' fontWeight='100' mediumText>
									By Date
								</Text>
								{renderDateFilter()}
							</ColumnFlex>
							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.lightGrey,
									padding: '10px 10px 10px 10px',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignStart
								gap='12px'
							>
								<Text fontStyle='bold' color='#969696' fontWeight='100' mediumText>
									By Time difference
								</Text>
								{renderTimeFilter()}
							</ColumnFlex>
						</PHWhiteCon>
						<div
							style={{
								flex: 5,
							}}
						>
							<SetTable
								title='Disputes'
								renderLegend={renderDisputesTableLegend}
								renderRows={renderTableRow}
								leftPadding='16px'
							/>
						</div>
					</RowFlex>
				</PHDColumn>
			</ParentContainer>
		</>
	);
};

export default AdminDisputesHandler;
