import React, { Component } from 'react';
import moment from 'moment';

import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Colors from 'colors';
import { RowFlex } from 'basicStyles';

export default class PaymentTableController extends Component {
	renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Date
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Payment Status
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Empty Status
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Shift Status
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Total Payment
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Total Hours Worked
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Client
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						CarePro
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon />
			</>
		);
	};

	renderStatusIconType = (status) => {
		if (status === 'authorized') {
			return (
				<div
					style={{
						height: '20px',
						width: '20px',
						backgroundColor: 'white',
						border: `2px solid ${Colors.greenLightTeal}`,
						borderRadius: '50%',
					}}
				/>
			);
		} else if (status === 'captured') {
			return (
				<div
					style={{
						height: '20px',
						width: '20px',
						backgroundColor: Colors.greenLightTeal,
						borderRadius: '50%',
					}}
				/>
			);
		} else {
			return (
				<div
					style={{
						height: '20px',
						width: '20px',
						backgroundColor: Colors.red,
						borderRadius: '50%',
					}}
				/>
			);
		}
	};

	renderShiftStatus = (status) => {
		//enum('created','clocked_in','clocked_out','submitted_shift','cancelled')
		switch (status) {
			case 'created':
				return (
					<div
						style={{
							height: '20px',
							width: '20px',
							backgroundColor: 'white',
							border: `2px solid ${Colors.blue}`,
							borderRadius: '50%',
						}}
					/>
				);
			case 'clocked_in' || 'clocked_out':
				return (
					<div
						style={{
							height: '20px',
							width: '20px',
							backgroundColor: Colors.blue,
							border: `2px solid ${Colors.blue}`,
							borderRadius: '50%',
						}}
					/>
				);
			case 'submitted_shift':
				return (
					<div
						style={{
							height: '20px',
							width: '20px',
							backgroundColor: Colors.greenLightTeal,
							border: `2px solid ${Colors.greenLightTeal}`,
							borderRadius: '50%',
						}}
					/>
				);
			case 'cancelled':
				return (
					<div
						style={{
							height: '20px',
							width: '20px',
							backgroundColor: Colors.red,
							border: `2px solid ${Colors.red}`,
							borderRadius: '50%',
						}}
					/>
				);
		}
	};
	renderRows = () => {
		const { payments, fetchUserDetails, history } = this.props;

		return payments.map((d, i) => {
			console.log('d.payment', d);
			return (
				<SetTable.RowAction key={'ptc-' + d.id}>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{new Date(d.timestamp).toLocaleDateString()}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<RowFlex fullWidth alignCenter gap='8px'>
							{this.renderStatusIconType(d.payment_status)}
							<Text
								fontStyle='italic'
								color='#969696'
								fontWeight='100'
								subtitle
								pointer
							>
								{d.payment_status}
							</Text>
						</RowFlex>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<RowFlex fullWidth alignCenter gap='8px'>
							Empty Column fix me
						</RowFlex>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<RowFlex fullWidth alignCenter gap='8px'>
							{this.renderShiftStatus(d.shift.status)}
							<Text
								fontStyle='italic'
								color='#969696'
								fontWeight='100'
								subtitle
								pointer
							>
								{d.shift.status}
							</Text>
						</RowFlex>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${(d.stripe_fee + d.subtotal) / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{(d.total_time_worked / 60).toFixed(2)}
						</Text>
					</SetTable.ColumnCon>

					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.carePlan.first_name + ' ' + d.carePlan.last_name}
						</Text>
					</SetTable.ColumnCon>

					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.provider.first_name + ' ' + d.provider.last_name}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Button
							style={{
								boxShadow: 'none',
								borderRadius: '24px',
								border: `2px solid ${Colors.blue}`,
							}}
							padding='12px 24px'
							color={Colors.blue}
							onClick={() => history.push(`/registry/payment/${d.id}`)}
						>
							View Info
						</Button>
					</SetTable.ColumnCon>
				</SetTable.RowAction>
			);
		});
	};
}
