import React, { useEffect, useState } from 'react';
import Colors from 'colors';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import SetTable from 'AppComp/SetTable';
import Icon from 'AppComp/Icon';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import { BasicTextInput } from 'AppComp/styled/StyledTextInput';
import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';

import { faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import {
	fetchCareAdvisorCarePlans,
	searchProviders,
	fetchUserDetails,
	fetchAdminsCarePlans,
} from 'reducers/admin';

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const ColumnConEnd = styled.div`
	flex: ${({ flex }) => flex || '1'};
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;

const CarePlanSearchHandler = (props) => {
	const { carePlans, fetchCareAdvisorCarePlans } = props;
	const [filteredCarePlans, setFilteredCaredPlans] = useState([]);

	const [keyword, setKeyword] = useState(''); // Search term
	const [totalShiftsOrder, setTotalShiftsOrder] = useState('ascending', 'descending');
	const [totalShiftOrderFilterEnabled, setTotalShiftsOrderFilterEnabled] = useState(true);

	useEffect(() => {
		fetchCareAdvisorCarePlans();
	}, []);

	useEffect(() => {
		const filteredCarePlans = carePlans.filter((carePlan) => {
			// Check if first_name and last_name are not null
			if (carePlan.first_name && carePlan.last_name) {
				// Convert both first_name, last_name, and keyword to lowercase for case-insensitive comparison
				const lowercaseFirstName = carePlan.first_name.toLowerCase();
				const lowercaseLastName = carePlan.last_name.toLowerCase();
				const lowercaseKeyword = keyword.toLowerCase();

				// Check if either first_name or last_name contains the keyword
				return (
					lowercaseFirstName.includes(lowercaseKeyword) ||
					lowercaseLastName.includes(lowercaseKeyword)
				);
			} else if (carePlan.first_name) {
				// If last_name is null, only check first_name
				const lowercaseFirstName = carePlan.first_name.toLowerCase();
				const lowercaseKeyword = keyword.toLowerCase();
				return lowercaseFirstName.includes(lowercaseKeyword);
			} else if (carePlan.last_name) {
				// If first_name is null, only check last_name
				const lowercaseLastName = carePlan.last_name.toLowerCase();
				const lowercaseKeyword = keyword.toLowerCase();
				return lowercaseLastName.includes(lowercaseKeyword);
			} else {
				// If both first_name and last_name are null, exclude the carePlan from the filtered result
				return false;
			}
		});

		setFilteredCaredPlans(filteredCarePlans);
	}, [keyword]);

	const handleKeywordChange = (e) => {
		setKeyword(e.target.value);
	};

	const renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Client Name
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Completed CarePlan
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Registry Approval
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Document Approval
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Total Jobs
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='0.5'></SetTable.ColumnCon>
			</>
		);
	};

	const sortCarePlans = (carePlans, sortOrder) => {
		return carePlans.sort((a, b) => {
			return sortOrder === 'ascending'
				? a.shifts.length - b.shifts.length
				: b.shifts.length - a.shifts.length;
		});
	};

	const handleTotalShiftsFilterClick = () => {
		const newSortOrder = totalShiftsOrder === 'ascending' ? 'descending' : 'ascending';
		setTotalShiftsOrder(newSortOrder);

		const sortedCarePlans = sortCarePlans(
			keyword === '' ? carePlans : filteredCarePlans,
			newSortOrder
		);
		setFilteredCaredPlans(sortedCarePlans);
	};

	const renderTotalShiftsFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor:
							totalShiftsOrder === 'ascending' ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleTotalShiftsFilterClick}
					disabled={!totalShiftOrderFilterEnabled}
				>
					{/* Render filter button with conditional styling based on enabled/disabled state */}
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={totalShiftsOrder === 'ascending' ? 'white' : Colors.emerald}
						midLarge
					>
						{totalShiftsOrder === 'ascending' ? 'Ascending Time' : 'Descending Time'}
					</Text>
					<Icon
						icon={totalShiftsOrder === 'ascending' ? faChevronUp : faChevronDown}
						color={totalShiftsOrder === 'ascending' ? 'white' : Colors.emerald}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
				{/* Render toggle button to enable/disable filter */}
				{/* Created just in case it's needed */}
				{/* <Button onClick={handleToggleUploadDateFilter}>
					{uploadDateFilterEnabled
						? 'Disable Upload Date Filter'
						: 'Enable Upload Date Filter'}
				</Button> */}
			</ColumnFlex>
		);
	};

	function getTotalJobsPerCarePlan(carePlan) {
		// Filter jobs with offer_status as accepted
		let activeJobs = carePlan.jobs.filter((job) => job.offer_status === 'accepted');
		// Return the count of active jobs for the carePlan
		return activeJobs.length;
	}

	function getTotalApplicationsPerCarePlan(carePlan, status) {
		let totalApplications = 0;

		carePlan.jobPosts.forEach((jobPost) => {
			totalApplications += jobPost.applications.reduce((count, application) => {
				return application.status === status ? count + 1 : count;
			}, 0);
		});

		return totalApplications;
	}

	const renderRows = () => {
		const renderedCarePlans = keyword === '' ? carePlans : filteredCarePlans;

		return renderedCarePlans.map((d, i) => {
			return (
				<SetTable.RowAction
					onClick={() => props.history.push(`/registry/clients/${d.id}`)}
					key={'time-rtr1-' + d.id}
				>
					<SetTable.ColumnCon flex='2'>
						<Text>{`${d.cr_first_name} ${d.cr_last_name}`}</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{d.completed_care_plan}
						</Text>
					</SetTable.ColumnCon>

					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{d.registry_approval}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{d.document_approval}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{getTotalJobsPerCarePlan(d)}
						</Text>
					</SetTable.ColumnCon>
					<ColumnConEnd
						style={{
							pointer: 'cursor',
						}}
						flex='0.5'
					>
						<div
							style={{
								cursor: 'pointer',
							}}
						>
							<Icon icon={faChevronRight} color={Colors.emerald} size='lg' />
						</div>
					</ColumnConEnd>
				</SetTable.RowAction>
			);
		});
	};

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<PHDColumn fullHeight>
				<RowFlex gap='30px' fullHeight fullWidth columnMobile>
					{/* <PHWhiteCon
						style={{
							flex: 1,
							backgroundColor: 'white',
							height: 'fit-content',
						}}
					>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Filter
						</Title>
						<ColumnFlex
							style={{
								overflowY: 'scroll',
							}}
							padding='16px 4px 0px 4px'
							fullWidth
							fullHeight
						>
							<div
								style={{
									padding: '0 16px 8px 16px',
								}}
							>
								<BasicTextInput
									margin={'20px 0 0px 0 '}
									value={keyword}
									onChange={handleKeywordChange}
									label={`Search By Client`}
									placeholder={`Search By Client`}
								/>
							</div>
						</ColumnFlex>

						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									Total Shifts
								</Text>
							</ColumnFlex>
							{renderTotalShiftsFilter()}
						</ColumnFlex>
					</PHWhiteCon> */}
					<div
						style={{
							flex: 5,
						}}
					>
						{carePlans.length === 0 ? (
							<Text>No Care Plans Found</Text>
						) : (
							<SetTable
								title='Care Plans'
								renderRows={renderRows}
								renderLegend={renderLegend}
								leftPadding='16px'
							/>
						)}
					</div>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ admin }) => {
	return {
		liteCarePlan: admin.liteCarePlan,
		carePlans: admin.carePlans,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		searchProviders: (input) => dispatch(searchProviders(input)),
		fetchUserDetails: (params) => dispatch(fetchUserDetails(params)),
		fetchAdminsCarePlans: () => dispatch(fetchAdminsCarePlans()),
		fetchCareAdvisorCarePlans: () => dispatch(fetchCareAdvisorCarePlans()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanSearchHandler);
