import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckUserSessionQuery } from '../../api/queries/useCheckUserSessionQuery';

export const AuthContext = createContext(null);

const initialAuthState = {
	isAuthenticated: false,
	isRegistryApproved: false,
	authUser: null,
	hasDefaultPaymentMethod: false,
	statusUpdate: null,
	hasCompletedPlatformOnboarding: false,
	hasDocumentsApproval: false,
	appIsLoading: true,
};

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState(initialAuthState);

	const {
		data,
		isLoading: appIsLoading,
		refetch: refreshAuthUser,
		isFetching: appIsReloading,
	} = useCheckUserSessionQuery();

	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_AUTH_TOKEN);

		if (!appIsLoading && !appIsReloading) {
			if (token && data) {
				const { carePlan, payment_methods } = data;

				const hasCompletedOnboarding = carePlan?.completed_platform_onboard || false;
				const hasApprovedDocuments = carePlan?.documents_approval || false;
				const hasDefaultPayment =
					payment_methods?.some((method) => method.default_card === 1) || false;
				const hasSetSchedule = carePlan?.has_set_schedule || false;

				setAuthState({
					isAuthenticated: true,
					authUser: data,
					isRegistryApproved: carePlan?.registry_approval || false,
					hasCompletedPlatformOnboarding: hasCompletedOnboarding,
					hasDocumentsApproval: hasApprovedDocuments,
					hasDefaultPaymentMethod: hasDefaultPayment,
					hasSetSchedule,
					statusUpdate: !hasCompletedOnboarding
						? 'Please add a care plan.'
						: !hasApprovedDocuments
						? 'Pending registry approval: please upload requested documents.'
						: !hasSetSchedule
						? 'Add a schedule for your care plan.'
						: null,
					appIsLoading: appIsLoading && appIsReloading,
				});
			} else {
				// Handle unauthenticated state
				setAuthState({
					isAuthenticated: false,
					authUser: null,
					isRegistryApproved: false,
					hasDefaultPaymentMethod: false,
					statusUpdate: null,
					hasCompletedPlatformOnboarding: false,
					hasDocumentsApproval: false,
					appIsLoading: appIsLoading && appIsReloading,
				});
			}
		}
	}, [data, appIsLoading, appIsReloading]);

	const loginUser = (token, user) => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN, token);
		refreshAuthUser();
	};

	const logoutUser = () => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN, null);
		setAuthState({ ...initialAuthState, appIsLoading: false });
	};

	const modifyAuthUser = (key, value) => {
		setAuthState((prevState) => ({
			...prevState,
			authUser: {
				...prevState.authUser,
				[key]: value,
			},
		}));
	};

	const value = {
		...authState,
		loginUser,
		logoutUser,
		modifyAuthUser,
		refreshAuthUser,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
