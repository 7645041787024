import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledPrivacyPolicyPage = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 1100px;
	padding: 15px;
	margin: auto;

	text-align: left;

	@media (${mediaQueries.tablet}) {
		width: fit-content;
	}
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0;

	@media ${mediaQueries.mobileM} {
		padding: 20px 0;
	}
`;

export const StyledPdfContainer = styled.button`
	align-self: right;
	cursor: pointer;
	background: none;
	border: none;
`;

export const StyledPrivacyPolicyContainer = styled.div``;
