import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
	StyledShift,
	StyledShiftHeader,
	StyledTimeContainer,
	StyledValue,
	StyledLabel,
	StyledCheckIcon,
} from './styles';

const ShiftItem = (props) => {
	const { shift, isSelected, isAvailable, onClickHandler } = props;

	return (
		<StyledShift isAvailable={isAvailable} isSelected={isSelected} onClick={onClickHandler}>
			<StyledShiftHeader>
				<StyledLabel>{shift.title}</StyledLabel>

				{isSelected && (
					<StyledCheckIcon>
						<FontAwesomeIcon size='xs' icon={faCheck} />
					</StyledCheckIcon>
				)}
			</StyledShiftHeader>

			<StyledTimeContainer>
				<StyledValue>{shift.startTime}</StyledValue>
				<StyledValue>{shift.endTime}</StyledValue>
			</StyledTimeContainer>
		</StyledShift>
	);
};

export default ShiftItem;
