import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { OFFER_MODEL } from '../../../core/api/models';

const respondToOfferMutation = async ({ offerId, accept }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					   careProRespondToOffer (offer_id: ${offerId}, accept: ${accept}) {
                        ${OFFER_MODEL}
                    }
				}`,
		});

		return data.careProRespondToOffer;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRespondToOfferMutation = () => {
	return useMutation({
		mutationKey: ['respondToOffer'],
		mutationFn: (values) => {
			return respondToOfferMutation(values);
		},
	});
};
