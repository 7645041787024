import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import {
	CARE_PRO_MODEL,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
} from '../../../core/api/models';

const registerCarePro = async ({
	email,
	password,
	firstName,
	lastName,
	phone,
	displayName,
	authCode,
	gender,
	registryId,
}) => {
	try {
		const data = await api.graph({
			query: `mutation{
					registerProvider(
						email: "${email}",
						password: "${password}",
						first_name: "${firstName}",
						last_name: "${lastName}",
						phone_number: "${phone}",
            display_name: "${displayName}",
						auth_code: "${authCode}",
						gender: "${gender}"
						registry_id: ${registryId}
					) {
						token
						provider {
							${CARE_PRO_MODEL}

							stripeStatus {
								${STRIPE_STATUS_MODEL}
							}
								
							careProRegistryStatus {
								${CARE_PRO_REGISTRY_STATUS_MODEL}
							}
						}
					
					}
				}`,
		});

		return { token: data.registerProvider.token, carePro: data.registerProvider.provider };
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRegisterCareProMutation = () => {
	return useMutation({
		mutationKey: ['registerCarePro'],
		mutationFn: (values) => {
			return registerCarePro(values);
		},
	});
};
