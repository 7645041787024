import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccountInfoPage from './AccountInfoPage';
import PersonalInfoPage from './PersonalInfoPage';
import AuthCodePage from './AuthCodePage';

import Steps from '../../../../../corev2/Steps';

import { OnboardingProvider } from '../../context/OnboardingContext';
import { useOnboarding } from '../../hooks/useOnboarding';

import { carePlanRoutes } from '../../../careplan/routes/constants';

import { StyledOnboardingPage, StyledStepsContainer } from './styles';

const OnboardingContent = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const { onboardingState } = useOnboarding();

	useLayoutEffect(() => {
		const steps = Object.values(onboardingState);

		const currentStep = steps.find((step) => !step.isCompleted);

		if (currentStep && location.pathname !== currentStep.route) {
			history.push(currentStep.route);
		} else if (!currentStep && location.pathname !== carePlanRoutes.home) {
			history.push(carePlanRoutes.home);
		}
	}, [onboardingState, history]);

	return (
		<StyledOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={onboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledOnboardingPage>
	);
};

const OnboardingPage = (props) => {
	return (
		<OnboardingProvider>
			<OnboardingContent {...props} />
		</OnboardingProvider>
	);
};

export { AccountInfoPage, PersonalInfoPage, AuthCodePage };
export default OnboardingPage;
