import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { OFFER_MODEL } from '../../../core/api/models';

const fetchCareProOffers = async ({ jobPostId }) => {
	const query = jobPostId
		? `{fetchCareProOffers(job_post_id: ${jobPostId}) {${OFFER_MODEL}}}`
		: `{fetchCareProOffers {${OFFER_MODEL}}}`;

	try {
		const data = await api.graph({ query });

		return data.fetchCareProOffers;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCareProOffersQuery = (jobPostId) => {
	return useQuery({
		queryKey: ['fetchCareProOffers', jobPostId],
		queryFn: () => {
			return fetchCareProOffers({ jobPostId });
		},
	});
};
