import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandSparkles } from '@fortawesome/free-solid-svg-icons';

import {
	StyledJobPost,
	StyledInformationContainer,
	StyledJobPostTitle,
	StyledJobPostDescription,
	StyledStatsContainer,
	StyledDateRange,
	StyledStatusContainer,
	StyledOffersContainer,
} from './styles';

const JobPost = (props) => {
	const { title, description, startDate, endDate, status, offers, goToJobPostOfferPage } = props;

	const getTitle = () => {
		switch (status) {
			case 'active':
				return 'This job post has received offers.';

			default:
				return 'Awaiting offers from Care Pros.';
		}
	};

	const formattedStartDate = moment(startDate).format('dddd, MMMM Do, YYYY');
	const formattedEndDate = moment(endDate).format('dddd, MMMM Do, YYYY');

	return (
		<StyledJobPost onClick={goToJobPostOfferPage}>
			<StyledInformationContainer>
				<StyledJobPostTitle>{title}</StyledJobPostTitle>

				<StyledJobPostDescription>{description}</StyledJobPostDescription>
				<StyledDateRange>
					{formattedStartDate} to {formattedEndDate}
				</StyledDateRange>
			</StyledInformationContainer>

			<StyledStatsContainer>
				{/* Status and Offers Section */}
				<StyledStatusContainer>
					<StyledOffersContainer title={getTitle()}>
						<FontAwesomeIcon size='sm' icon={faHandSparkles} /> {offers}
					</StyledOffersContainer>
				</StyledStatusContainer>
			</StyledStatsContainer>
		</StyledJobPost>
	);
};

export default JobPost;
