import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { StyledBackButton } from './styles';

const BackButton = (props) => {
	const { clickHandler, withText } = props;

	return (
		<StyledBackButton onClick={clickHandler}>
			<FontAwesomeIcon icon={faChevronLeft} />

			{withText && 'Back'}
		</StyledBackButton>
	);
};

export default BackButton;
