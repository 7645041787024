import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { carePlanRoutes } from '../../routes/constants';

import { coreRoutes } from '../../../core/routes/constants';

export const CarePlanContext = createContext();

const initialCarePlanState = {
	careCoordinator: {
		name: 'careCoordinator',
		label: 'Care Coordinator',
		isCompleted: false,
		data: {
			relationship: '',
		},
		route: carePlanRoutes.onboarding.relationship,
	},
	careRecipient: {
		name: 'careRecipient',
		label: 'Care Recipient',
		isCompleted: false,
		data: {},
		route: carePlanRoutes.onboarding.info,
	},
	contactInfo: {
		name: 'contactInfo',
		label: 'Contact Info',
		isCompleted: false,
		data: {},
		route: carePlanRoutes.onboarding.personal,
	},
	careLocation: {
		name: 'careLocation',
		label: 'Care Location',
		isCompleted: false,
		data: {},
		route: carePlanRoutes.onboarding.logistics,
	},
	careNeeds: {
		name: 'careNeeds',
		label: 'Care Needs',
		isCompleted: false,
		data: {},
		route: carePlanRoutes.onboarding.careNeeds,
	},
};

export const CarePlanProvider = ({ children }) => {
	const [carePlanState, setCarePlanState] = useState(initialCarePlanState);

	const history = useHistory();

	useEffect(() => {
		const localCarePlanState = getFromLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE);

		if (localCarePlanState) {
			setCarePlanState(localCarePlanState);

			// hard check to ensure if all are completed to take the user to the document upload page

			const allCompleted = Object.values(localCarePlanState).every(
				(state) => state.isCompleted
			);

			if (allCompleted) {
				//history.push(coreRoutes.documents);
			}
		}
	}, []);

	const mutateCarePlanState = (key, value) => {
		setCarePlanState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE, JSON.stringify(newState));
			return newState;
		});
	};

	const resetCarePlanState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE);
		setCarePlanState(initialCarePlanState);
	};

	const value = {
		carePlanState,
		mutateCarePlanState,
		resetCarePlanState,
	};

	return <CarePlanContext.Provider value={value}>{children}</CarePlanContext.Provider>;
};
