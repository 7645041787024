import { createAction, createActions, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import api from '../../../scripts/api';

import {
	PROVIDER_MODEL_V2,
	CAREPLAN_MODEL_V2,
	MESSAGE_MODEL,
	PAYMENT_TYPE_MODEL,
	PATIENT_MODEL_V2,
	PATIENT_INFO_CHANGE_MODEL,
	VERIFICATION_MODEL,
	AGENCY_CARE_PLAN_MODEL,
	DOCUMENT_REQUIREMENTS,
	CONVERSATION_MODEL,
	CORE_JOB_MODEL,
	CARE_NEEDS_MODEL,
	CORE_APPLICATION_MODEL,
} from '../../graphqlModelTypes';

import {
	toggleAdminSelectedProvider,
	receiveSelectedCarePlanId,
	receiveCarePlanOnly,
	receiveCarePlansAction,
	receiveProviders,
	receivePatients,
	receiveConversations,
	receiveSelectedConversation,
	receiveUserDetails,
	receiveSelectedConversationType,
	receiveClientDocumentRequirements,
	receiveCareProDocumentRequirements,
	receiveAttributeTraits,
	receiveTraitTemplates,
} from '../actions';

import { fetchPayment } from './adminCarePlan';
import { receiveError, showInfoModel } from '../../environment';
import { fetchCarePlanModel } from './adminCarePlan';
import { create } from 'lodash';
import { DEFAULT_TOAST_OPTIONS } from '../../../components/App';

const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};

export function toggleNewMessageStatus(carePlanId) {
	return (dispatch, getState) => {
		const { carePlans } = getState().admin;

		const filteredCarePlans = carePlans.map((d, i) => {
			if (d.id === carePlanId) {
				return {
					...d,
					user: {
						...d.user,
						adminPatientConversation: {
							...d.user.adminPatientConversation,
							newMessage: false,
						},
					},
				};
			} else {
				return d;
			}
		});

		dispatch(receiveCarePlansAction(filteredCarePlans));
	};
}

export function fetchCareAdvisorCarePlans() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchCareAdvisorCarePlans {
					${CAREPLAN_MODEL_V2}
					jobs {
						id
					}

				}
			}`,
		})
			.then((res) => {
				dispatch(receiveCarePlansAction(res.fetchCareAdvisorCarePlans));
			})
			.catch((err) => {
				console.log('fetchCareAdvisorCarePlans err', err);
			});
	};
}
export function toggleNewMessageProvider(carePlanId, adminProviderConvoId) {
	return (dispatch, getState) => {
		const { carePlans } = getState().admin;

		const updatedCarePlans = carePlans.map((d, i) => {
			if (d.id === adminProviderConvoId) {
				const updatedProviderConvo = d.adminProviderConversations.map((x, i) => {
					if (x.id === adminProviderConvoId) {
						return {
							...x,
							newMessage: false,
						};
					} else {
						return x;
					}
				});

				return {
					...d,
					adminProviderConversations: updatedProviderConvo,
				};
			} else {
				return d;
			}
		});

		dispatch(receiveCarePlansAction(updatedCarePlans));
	};
}

export function receiveSelectedCarePlanInfo({ id, adminProviderConversations }) {
	return (dispatch, getState) => {
		//This handles updating the slected careplan id

		//Handles updating the selected careplan provider conversation id
		if (adminProviderConversations.length > 0) {
			dispatch(toggleAdminSelectedProvider(adminProviderConversations[0].id));
		} else {
			dispatch(toggleAdminSelectedProvider(null));
		}

		dispatch(receiveSelectedCarePlanId(id));
	};
}

/**
	Updates only the careplans and nothing else
	params = {
		carePlans: [careplan]!
	}
**/

export function receiveCarePlans(carePlans) {
	return (dispatch) => {
		// dispatch({
		// 	type: 'RECEIVE_CAREPLANS',
		// 	carePlans
		// })
		dispatch(receiveCarePlansAction(carePlans));
	};
}

export function fetchUserDetails({ id, type }) {
	return (dispatch) => {
		if (type === 'provider') {
			dispatch(fetchProivder(id));
		} else if (type === 'payment') {
			dispatch(fetchPayment(id));
		} else if (type === 'carePlan') {
			dispatch(fetchCarePlanModel(id));
		} else {
			dispatch(fetchPatient(id));
		}
	};
}

export function searchCareplans() {
	console.log('kilaslfja;d');
}

export function adminSendMessageToConversation({ conversation_id, text }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				adminSendMessageToConversation(conversation_id: ${conversation_id}, text: "${text}") 
			}`,
		})
			.then((res) => {
				console.log('adminSendMessageToConversation res', res);
			})
			.catch((err) => {
				console.log('adminSendMessageToConversation err ', err);
			});
	};
}
export function fetchAdminConversation(conversation_id) {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAdminConversation(conversation_id: ${conversation_id}) {
					id
					user_type,
					unread_message,
					latest_message_timestamp,
					latest_message,
					user {
						${PATIENT_MODEL_V2}
						verification {
							valid,
							
							patient_personal_details_submitted,
							patient_location_details_submitted,
							payment_method_verification,
							
					 
							valid_email,
							valid_phone,
							missing_information
						},
						carePlan {
							${CAREPLAN_MODEL_V2}
							jobs {
								${CORE_JOB_MODEL}
							}
						
						}
					
					},
					provider {
						${PROVIDER_MODEL_V2},
					},
					messages {
						${MESSAGE_MODEL}
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveSelectedConversation(res.fetchAdminConversation));
			})
			.catch((err) => {
				console.log('fetchAdminConversation err', err);
			});
	};
}
export function fetchAdminConversations(user_type = null) {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAdminConversations ${user_type ? `(user_type: "${user_type}")` : ''} {
					${CONVERSATION_MODEL}
					messages {
						${MESSAGE_MODEL}

					}
					
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveSelectedConversationType(user_type));
				const convos = res.fetchAdminConversations.filter((d, i) => {
					if (d.provider === null && d.user === null) {
						return false;
					} else return true;
				});
				dispatch(receiveConversations(convos));
			})
			.catch((err) => {
				console.log('fetchAdminConversations err', err);
			});
	};
}

export function searchProviders(input) {
	return (dispatch) => {
		api.graph({
			query: `{
				adminSearchProviders ${input ? `(input: "${input}")` : ''}
				{
					${PROVIDER_MODEL_V2},
				
					
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveProviders(res.adminSearchProviders));
			})
			.catch((err) => {
				console.log('admin searchProviders err', err);
			});
	};
}

export function searchPatients(input) {
	return (dispatch) => {
		api.graph({
			query: `{
				adminSearchPatients ${input ? `(input: "${input}")` : ''} 
				{
					${PATIENT_MODEL_V2},
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePatients(res.adminSearchPatients));
			})
			.catch((err) => {
				console.log('adminSearchPatients err', err);
			});
	};
}

export function fetchPatient(id) {
	return (dispatch) => {
		dispatch(receiveUserDetails({ status: true, user: null }));

		api.graph({
			query: `{
				user(id: ${id}) {
					${PATIENT_MODEL_V2}
					conversations {
						${CONVERSATION_MODEL}
					
						messages {
							${MESSAGE_MODEL}
						}
					},
					verification {
						valid,
						error_code,
						error_reason,
						patient_personal_details_submitted,
						patient_location_details_submitted,
						payment_method_verification,
						
						error_code,
						error_reason,
						valid_email,
						valid_phone,
						missing_information
					},
					carePlan {
						${CAREPLAN_MODEL_V2}
						jobs {
							${CORE_JOB_MODEL}
						}

					
						
					
					
						
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveUserDetails({
						status: true,
						user: res.user,
						type: 'patient',
					})
				);
			})
			.catch((err) => {
				console.log('fetchPatient err', err);
			});
	};
}

export function fetchProivder(id) {
	return (dispatch) => {
		dispatch(receiveUserDetails({ status: true, user: null }));

		api.graph({
			query: `{
				provider(id: ${id}) {
					${PROVIDER_MODEL_V2},
					averageReview,
				
					
					conversations {
						${CONVERSATION_MODEL}
						messages {
							${MESSAGE_MODEL}

						}
					},
					blockedTime {
						id,
						day_of_week,
						start_time,
						end_time,
					},
				
				
				
					payments {
						${PAYMENT_TYPE_MODEL}
					},
					shifts {
						id
					}
				
					
				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveUserDetails({
						status: true,
						user: res.provider,
						type: 'provider',
					})
				);
			})
			.catch((err) => {
				console.log('fetchProivder err', err);
			});
	};
}

export function adminSendMessageToPatient(params) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				adminSendMessageToPatient(
					adminPatientConversationId: ${params.adminPatientConversationId},
					text: "${params.text}"
				) {
					id,
					messages {
						${MESSAGE_MODEL}
					}
				}
			}`,
		})
			.then((payload) => {
				const { carePlans } = getState().admin;

				const updatedCarePlans = carePlans.map((d, i) => {
					if (
						d.user.adminPatientConversation.id == payload.adminSendMessageToPatient.id
					) {
						d.user.adminPatientConversation = payload.adminSendMessageToPatient;
					}

					return d;
				});

				dispatch(receiveCarePlanOnly(updatedCarePlans));
			})
			.catch((err) => {
				console.log('adminSendMessageToPatient err', err);
			});
	};
}

export function fetchClientDocumentRequirement() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchClientDocumentRequirement {
					${DOCUMENT_REQUIREMENTS}
				}
			}`,
		})
			.then((res) => {
				if (res.fetchClientDocumentRequirement) {
					dispatch(receiveClientDocumentRequirements(res.fetchClientDocumentRequirement));
				}
			})
			.catch((err) => {
				console.log('fetchClientDocumentRequirement err', err);
			});
	};
}
export function fetchCareProDocumentRequirement() {
	return (dispatch) => {
		api.graph({
			query: `{
				registryFetchCareProDocumentRequirement {
					${DOCUMENT_REQUIREMENTS}
				}
			}`,
		})
			.then((res) => {
				if (res.registryFetchCareProDocumentRequirement) {
					dispatch(
						receiveCareProDocumentRequirements(
							res.registryFetchCareProDocumentRequirement
						)
					);
				}
			})
			.catch((err) => {
				console.log('registryFetchCareProDocumentRequirement err', err);
			});
	};
}

export function addDocumentsRequirements({ name, required, user_type, requires_expiration }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				addDocumentsRequirements(
					name: "${name}",
					required: ${required},
					user_type: "${user_type}"

					requires_expiration: ${requires_expiration}
				) {
					${DOCUMENT_REQUIREMENTS}
				}
			}`,
		})
			.then((res) => {
				if (res.addDocumentsRequirements) {
					toast.success('Successfully added Document requirement ', toastSuccessConfig);
					if (user_type === 'client') {
						dispatch(receiveClientDocumentRequirements(res.addDocumentsRequirements));
					} else {
						dispatch(receiveCareProDocumentRequirements(res.addDocumentsRequirements));
					}
					//dispatch(receiveClientDocumentRequirements(res.addDocumentsRequirements));
				}
			})
			.catch((err) => {
				console.log('addDocumentsRequirements err', err);
				toast.error(
					'Something went wrong trying to add the Document requirement',
					toastErrorConfig
				);
			});
	};
}

export function removeDocumentsRequirements({ id, user_type }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				removeDocumentsRequirements(id: ${id}) {
					${DOCUMENT_REQUIREMENTS}
				}
			}`,
		})
			.then((res) => {
				if (res.removeDocumentsRequirements) {
					toast.success('Successfully Removed Document Requirement ', toastSuccessConfig);
					if (user_type === 'client') {
						dispatch(
							receiveClientDocumentRequirements(res.removeDocumentsRequirements)
						);
					} else {
						dispatch(
							receiveCareProDocumentRequirements(res.removeDocumentsRequirements)
						);
					}
					//dispatch(receiveClientDocumentRequirements(res.removeDocumentsRequirements));
				}
			})
			.catch((err) => {
				console.log('addDocumentsRequirements err', err);
				toast.error(
					'Something went wrong trying to remove Document requirement',
					toastErrorConfig
				);
			});
	};
}

export function fetchAttributeTraits() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAllAttributeTraits {
					id,
					label,
					trait_type,
					description
				}
			}`,
		})
			.then((res) => {
				if (res.fetchAllAttributeTraits)
					dispatch(receiveAttributeTraits(res.fetchAllAttributeTraits));
			})
			.catch((err) => {
				console.log('fetchAttributeTraits err', err);
			});
	};
}

export function createAttributeTrait({ label, description, type, required, form, callBackFn }) {
	console.log('is createAttributeTrait running');
	return (dispatch, getState) => {
		api.graph({
			query: `mutation {
				createAttributeTrait(
					label: "${label}",
					trait_type: "${type}",
					required: ${required}
					${description ? `, description: "${description}"` : ''}
				) {
					id,
					label,
					trait_type,
					description
				}
			}`,
		})
			.then((res) => {
				if (res.createAttributeTrait) {
					// get the attributetrait state and add it
					const { attributeTraits } = getState().admin;
					const updatedAttributeTraits = [...attributeTraits, res.createAttributeTrait];
					dispatch(receiveAttributeTraits(updatedAttributeTraits));
					callBackFn(form);
					toast.success('Successfully Added Attribute Trait', toastSuccessConfig);
				}
			})
			.catch((err) => {
				console.log('fetchAttributeTraits err', err);

				toast.error(
					'Something went wrong trying to add the a Attribute Trait',
					toastErrorConfig
				);
			});
	};
}

export function fetchTraitTemplates() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAllTraitTemplates {
					id,
					template_label,
					attributeTraitTemplateHelpers {
						id,
						active,
						attribute_trait_id,
						attributeTrait {
							id,
							label,
							trait_type,
							description
						}
					}
				}
			}`,
		})
			.then((res) => {
				if (res.fetchAllTraitTemplates)
					dispatch(receiveTraitTemplates(res.fetchAllTraitTemplates));
			})
			.catch((err) => {
				console.log('fetchTraitTemplates err', err);
			});
	};
}

/* 
	takes a array of traits info
	{
		trait_id: int
		trait_template_id: int
	}
*/
export function addTraitToTemplate({ selectedTraits, callBackFn, templateId }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation {
				addTraitsToTemplate(
					traits: ${api.graphStringify(selectedTraits)}
				) {
					id,
					active,
					attribute_trait_id,
					attributeTrait {
						id,
						label,
						trait_type,
						description
					}
				}
			}`,
		})
			.then((res) => {
				if (res.addTraitsToTemplate) {
					// get local state
					const { traitTemplates } = getState().admin;
					// update local state
					// get the trait template that matches trait_template_id
					const traitTemplate = traitTemplates.find((d, i) => d.id === templateId);

					// this almost seem's pointless but
					// if the train template is not found something went really wrong
					if (traitTemplate) {
						// update the correct trait helper within traitTemplate
						const updatedAttributeTraitHelpers = res.addTraitsToTemplate;

						// update the trait template with the new updated updatedAttributeTraitHelpers
						const updatedTraitTemplates = traitTemplates.map((d, i) => {
							if (d.id === trait_template_id) {
								return {
									...d,
									attributeTraitTemplateHelpers: updatedAttributeTraitHelpers,
								};
							} else {
								return d;
							}
						});
						// dispatch the updated trait templates
						dispatch(receiveTraitTemplates(updatedTraitTemplates));
						// confirm the action with toast
						toast.success('Successfully Added Trait', toastSuccessConfig);
						// callback (true for success)
						callBackFn(true);
					}
				}
			})
			.catch((err) => {
				console.log('addTraitToTemplate err', err);
				toast.error(
					'Something went wrong trying to add the a Trait to the Template',
					toastErrorConfig
				);
				callBackFn(false);
			});
	};
}

export function deactivateTraitFromTemplate({ trait_template_helper_id, trait_template_id }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation {
				deactivateTraitFromTemplate ( trait_template_helper_id: ${trait_template_helper_id}) {
					id,
					active,
					attribute_trait_id,
					attributeTrait {
						id,
						label,
						trait_type,
						description
					}
				}
			}`,
		})
			.then((res) => {
				console.log('deactivateTraitFromTemplate res', res);
				if (res.deactivateTraitFromTemplate) {
					// get local state
					const { traitTemplates } = getState().admin;
					// update local state
					// get the trait template that matches trait_template_id
					const traitTemplate = traitTemplates.find((d, i) => d.id === trait_template_id);

					// this almost seem's pointless but
					// if the train template is not found something went really wrong
					if (traitTemplate) {
						// update the correct trait helper within traitTemplate
						const updatedAttributeTraitHelpers =
							traitTemplate.attributeTraitTemplateHelpers.map((d, i) => {
								if (d.id === trait_template_helper_id) {
									return {
										...d,
										active: !d.active,
									};
								} else {
									return d;
								}
							});

						// update the trait template with the new updated updatedAttributeTraitHelpers
						const updatedTraitTemplates = traitTemplates.map((d, i) => {
							if (d.id === trait_template_id) {
								return {
									...d,
									attributeTraitTemplateHelpers: updatedAttributeTraitHelpers,
								};
							} else {
								return d;
							}
						});
						// dispatch the updated trait templates
						dispatch(receiveTraitTemplates(updatedTraitTemplates));
						// confirm the action with toast
						toast.success('Successfully Updated Template', toastSuccessConfig);
					}
				}
			})
			.catch((err) => {
				console.log('deactivateTraitFromTemplate err', err);
			});
	};
}
