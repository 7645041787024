import moment from 'moment';

export const sortShifts = (shifts) => {
	return shifts.sort((a, b) => {
		const dateA = moment(a.start_time); // Assuming start_time is a JS Date string
		const dateB = moment(b.start_time);

		return dateA.diff(dateB);
	});
};
