import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Loader from '../../../corev2/Loader';
import NotificationContainer from './components/NotificationsContainer';
import NavBar from './components/NavBar';
import Wrapper from './components/Wrapper';

import AppRoutes from './routes';

import { coreRoutes } from './routes/constants';
import { authRoutes } from '../auth/routes/constants';
import { documentsRoutes } from '../documents/routes/constants';

import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';

const CareProAppContent = () => {
	const history = useHistory();

	const {
		appIsLoading,
		isAuthenticated,
		authCarePro,
		pendingStripeDocumentRequirements,
		statusUpdate,
	} = useAuth();

	/**
	 *  Based on the auth context of the care pro, make all redirects here.
	 *  Please restrict yourself to making all redirects happen in the App.jsx file
	 */

	useEffect(() => {
		if (isAuthenticated && authCarePro) {
			const { professional_statement, stripeStatus, careProRegistryStatus } = authCarePro;

			// Redirect to the professional statement page if not set
			if (professional_statement === null) {
				history.push(authRoutes.onboarding.professionalStatement);
			}

			// Redirect to Stripe onboarding if unverified
			else if (stripeStatus?.status === 'unverified') {
				if (stripeStatus.stripe_data && stripeStatus.stripe_data.currently_due) {
					// ensure that we move the user to docs view after they've completed most of the stripe onboarding

					// check the length of the currently due stripe documents
					// ensure currently due is in model

					if (stripeStatus.stripe_data.currently_due) {
						// if individual.verification.document is in currently due take them to the documents page
						if (
							stripeStatus.stripe_data.currently_due.includes(
								'individual.verification.document'
							)
						) {
							history.push(documentsRoutes.stripeDocuments);
						} else {
							history.push(authRoutes.onboarding.stripe.address);
						}
					}
				} else {
					history.push(authRoutes.onboarding.home);
				}
			}
			// Redirect to documents page if approval is incomplete
			else if (!careProRegistryStatus?.documents_approval) {
				history.push(coreRoutes.documents);
			}
		}
	}, [isAuthenticated, authCarePro, history]);

	return (
		<>
			{appIsLoading && <Loader />}

			{!appIsLoading && (
				<>
					<NavBar statusUpdate={statusUpdate} />
					<Wrapper>
						<AppRoutes />
					</Wrapper>
				</>
			)}
		</>
	);
};

const CareProApp = () => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<NotificationContainer />
			<AuthProvider>
				<CareProAppContent />
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default CareProApp;
