import React from 'react';
import { useFormik } from 'formik-latest';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import {
	FormContainer,
	Checkboxes,
	Select,
	ErrorMessage,
	Input,
} from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

const sectionSchema = z.object({
	cc_relation_to_cr: z.string().nonempty('Relationship is required'),
	// ensure that relationship is not empty or null

	cc_first_name: z.string().nonempty('Care Coordinator First Name is required'),
	cc_last_name: z.string().nonempty('Care Coordinator Last Name is required'),
	cc_phone_number: z.string().nonempty('Care Coordinator Phone Number is required'),
	//cc_relation_to_cr: z.string().nonempty('Care Coordinator Relationship to Care Receiver is required'),

	// options is not required but if there are any it none of them should be identical
	//options: z.array(z.string()).nonempty('Options are required').unique(),
});

const RelationshipForm = (props) => {
	const { serverError, initialValues, relationshipHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => relationshipHandler(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(sectionSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<h4
						style={{
							letterSpacing: '.1rem',
						}}
					>
						Care Coordinator Relationship to Care Receiver
					</h4>
					<Select
						name='cc_relation_to_cr'
						onChange={formik.handleChange}
						placeholder='Select Care Coordinator Relationship to Care Receiver'
						values={formik.values.relationship}
						defaultValue=''
					>
						<option disabled selected value=''>
							Select Care Coordinator Relationship to Care Receiver
						</option>
						<option value='father'>Father</option>
						<option value='mother'>Mother</option>
						<option value='son'>Son</option>
						<option value='daughter'>Daughter</option>
						<option value='friend'>Friend</option>
						<option value='employee'>Employee</option>
						<option value='self'>Care Coordinator is also Care Receiver</option>
					</Select>
					<ErrorMessage>
						{formik.errors.relationship && formik.errors.relationship}
					</ErrorMessage>
				</InputContainer>
				<InputContainer>
					<Input
						title={'Care Coordinator First Name'}
						placeholder='Care Coordinator First Name'
						onChange={formik.handleChange}
						name='cc_first_name'
						value={formik.values.cc_first_name}
						error={formik.errors.cc_first_name}
					/>
				</InputContainer>
				<InputContainer>
					<Input
						title={'Care Coordinator Last Name'}
						placeholder='Care Coordinator Last Name'
						onChange={formik.handleChange}
						name='cc_last_name'
						value={formik.values.cc_last_name}
						error={formik.errors.cc_last_name}
					/>
				</InputContainer>
				<InputContainer>
					<Input
						title={'Care Coordinator Phone Number'}
						placeholder='Care Coordinator Phone Number'
						onChange={formik.handleChange}
						name='cc_phone_number'
						value={formik.values.cc_phone_number}
						error={formik.errors.cc_phone_number}
					/>
				</InputContainer>
				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Next
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default RelationshipForm;
