import React, { useEffect, useState } from 'react';

import { Container, Title, GridContainer, Item, Label, Input } from './CoreView';

export const CarePlanDataView = (props) => {
	const {
		completed_care_plan,
		completed_platform_onboard,
		completed_registry_onboard,
		registry_approval,
		documents_approval,
		cc_first_name,
		cc_last_name,
		cr_first_name,
		cr_last_name,
		cr_display_name,
		cc_phone_number,
		cc_relation_to_cr,
		cr_dob,
		cr_gender,
		care_zip,
		care_city,
		registry_id,
	} = props;
	return (
		<>
			<Title>Care Plan Info</Title>
			<GridContainer columns={4}>
				<Item>
					<Label>Completed CarePlan</Label>
					<Input>{completed_care_plan || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Completed Platform Onboarding</Label>
					<Input>{completed_platform_onboard || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Completed Registry Onboarding</Label>
					<Input>{completed_registry_onboard || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Registry Approval</Label>
					<Input>{registry_approval || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Documents Approval</Label>
					<Input>{documents_approval || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Care City</Label>
					<Input>{care_city || 'N/A'}</Input>
				</Item>
				<Item>
					<Label>Care Zip</Label>
					<Input>{care_zip || 'N/A'}</Input>
				</Item>
			</GridContainer>

			<Title>Care Coordinator</Title>
			<GridContainer columns={3}>
				<Item>
					<Label>First Name</Label>
					<Input>{cc_first_name}</Input>
				</Item>
				<Item>
					<Label>Last Name</Label>
					<Input>{cc_last_name}</Input>
				</Item>
				<Item>
					<Label>Phone Number</Label>
					<Input>{cc_phone_number}</Input>
				</Item>
				<Item>
					<Label>Gender</Label>
					<Input>{cr_gender}</Input>
				</Item>
				<Item>
					<Label>Relation to Recipient </Label>
					<Input>{cc_relation_to_cr}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

export default CarePlanDataView;
