import React, { useState } from 'react';
import Colors from 'colors';

import {
	FormDisplaySection,
	StyledFormPage,
	StyledPageHeadingContainer,
	PageHeading,
	FormItemsContainer,
	FormItem,
} from './styles';

import Text from 'AppComp/Text';
import Tabs from '../../core/Tabs';

import useFetchRegistryTraitTemplates from '../api/query/useFetchRegistryTraitTemplates';

const initialTabState = {
	client: {
		name: 'Client',
		value: 'client',
		selected: true, // Default to 'Client' tab
	},
	carePro: {
		name: 'CarePro',
		value: 'carePro',
		selected: false,
	},
};

// Form categories for filtering
const formCategories = {
	client: [
		'Care Receiver Info',
		'CarePlan Contact Info',
		'Care Location Info',
		'Care Plan Needs',
	],
	carePro: ['CarePro Job Skills'],
};

const FormTemplates = () => {
	const [tabOptions, setTabOptions] = useState(initialTabState);

	const { data: formTemplates = [], isLoading } = useFetchRegistryTraitTemplates();
	const handleTabChange = (selectedTabName) => {
		const updatedTabs = Object.fromEntries(
			Object.entries(tabOptions).map(([key, option]) => [
				key,
				{ ...option, selected: option.name === selectedTabName },
			])
		);
		setTabOptions(updatedTabs);
	};

	// Determine the active tab (either "Client" or "CarePro")
	const activeTab = Object.values(tabOptions).find((tab) => tab.selected)?.value;

	// Filter form templates based on the selected tab
	const filteredTemplates = formTemplates.filter((form) =>
		formCategories[activeTab]?.includes(form.template_label)
	);

	return (
		<StyledFormPage>
			<StyledPageHeadingContainer>
				<PageHeading styles={{ padding: '20px 0 0 0' }}>Form Templates</PageHeading>
			</StyledPageHeadingContainer>

			<div
				style={{
					width: '100%',
					maxWidth: '670px',
					marginRight: 'auto',
					marginLeft: 'auto',
				}}
			>
				<Tabs options={tabOptions} selectHandler={handleTabChange} />
			</div>

			<FormDisplaySection>
				<Text fontWeight='500' color={Colors.primary.primary} largeText>
					{activeTab === 'client' ? 'Client Form Templates' : 'CarePro Form Templates'}
				</Text>
				<Text fontWeight='300' largeText>
					Manage Form Templates here, Click on any to view the form in detail
				</Text>

				<FormItemsContainer>
					{isLoading ? (
						<Text>Loading...</Text>
					) : filteredTemplates.length > 0 ? (
						filteredTemplates.map((form) => <FormItem key={form.id} {...form} />)
					) : (
						<Text>No templates found for the selected category.</Text>
					)}
				</FormItemsContainer>
			</FormDisplaySection>
		</StyledFormPage>
	);
};

export default FormTemplates;
