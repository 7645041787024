import styled from 'styled-components';

import { colors } from '../utils';

export const StyledTabsContainer = styled.div`
	display: flex;
	background-color: ${colors.table.alternative};
	padding: 4px 8px;
	border-radius: 4px;
`;

export const StyledTabItem = styled.div`
	padding: 8px;
	font-size: 14px;
	border-radius: 4px;
	background: ${(props) => (props.selected ? colors.white : colors.table.alternative)};};
	color: ${colors.forms.fontColor};
	cursor: pointer;
`;
