import React from 'react';
import moment from 'moment';

import Text from '../../../../../corev2/Text';
import { PrimaryButton, DangerButton, TransparentButton } from '../../../../../corev2/Buttons';

import ShiftItem from '../../../core/components/ShiftItem';

import {
	StyledOfferModalContainer,
	StyledOfferModalWrapper,
	StyledShiftDetails,
	StyledShiftsContainer,
	StyledTitle,
	StyledButtonContainer,
} from './styles';

const OfferModal = (props) => {
	const {
		title,
		offer,
		isModificationAllowed,
		isValid,
		viewOfferModalHandler,
		respondToOfferHandler,
	} = props;

	return (
		<StyledOfferModalContainer>
			<StyledOfferModalWrapper>
				<StyledTitle>{title}</StyledTitle>
				{!isValid && (
					<Text>
						This offer is no longer valid because some shifts are no longer available.
					</Text>
				)}
				<StyledShiftDetails>
					<StyledShiftsContainer>
						{offer.offered_shifts.map((offered_shift) => {
							const { shift } = offered_shift;

							return (
								<ShiftItem
									isAvailable={shift.status === 'pending'}
									key={shift.id}
									shift={{
										...shift,
										title: 'Care Session',
										startTime: moment(
											`${shift.start_date} ${shift.start_time}`
										).format('MMMM D, YYYY [at] h:mm A'),
										endTime: moment(
											`${shift.end_date} ${shift.end_time}`
										).format('MMMM D, YYYY [at] h:mm A'),
									}}
									isSelected={false}
								/>
							);
						})}
					</StyledShiftsContainer>
				</StyledShiftDetails>

				<StyledButtonContainer>
					{isModificationAllowed && (
						<>
							<PrimaryButton
								size='small'
								type='submit'
								disabled={offer.offered_shifts.length === 0}
								onClick={() => respondToOfferHandler(offer.id, true)}
							>
								Accept Offer
							</PrimaryButton>

							<DangerButton
								size='small'
								type='submit'
								disabled={offer.offered_shifts.length === 0}
								onClick={() => respondToOfferHandler(offer.id, false)}
							>
								Reject Offer
							</DangerButton>
						</>
					)}
					<TransparentButton onClick={viewOfferModalHandler} size='small'>
						Cancel
					</TransparentButton>
				</StyledButtonContainer>
			</StyledOfferModalWrapper>
		</StyledOfferModalContainer>
	);
};

export default OfferModal;
