import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const addScheduleToCarePlan = async ({
	startDate,
	startTime,
	endDate,
	endTime,
	repeatFrequency,
	daysOfWeek,
	carePlanId,
	shifts,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
                addScheduleToCarePlan(
					start_date: "${startDate}",
                    start_time: "${startTime}",
					end_date: "${endDate}",
                    end_time: "${endTime}",
					repeat_frequency: "${repeatFrequency}",
					days_of_week: "${daysOfWeek}",
                    care_plan_id: ${carePlanId},
					shifts: ${api.graphStringify(shifts)}
                    ) {
                    id
                }
            }`,
		});

		return data.addScheduleToCarePlan;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useAddScheduleToCarePlanMutation = () => {
	return useMutation({
		mutationKey: ['addScheduleToCarePlan'],
		mutationFn: (values) => {
			return addScheduleToCarePlan(values);
		},
	});
};
