import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import PageHeading from '../../../core/components/PageHeading';
import LoginForm from '../../components/LoginForm';

import { useLoginCareProMutation } from '../../api/mutations/useLoginCareProMutation';
import { useAuth } from '../../../core/hooks/useAuth';

import Notification from '../../../core/lib/notification';
import { notifications } from '../../../core/constants/notifications';

import { coreRoutes } from '../../../core/routes/constants';
import { authRoutes } from '../../../auth/routes/constants';

import { StyledLoginPage } from './styles';

const LoginPage = () => {
	const [serverError, setServerError] = useState('');

	const loginMutation = useLoginCareProMutation();
	const { loginCarePro } = useAuth();

	const history = useHistory();

	const loginHandler = async (values) => {
		try {
			setServerError('');

			let registryId = 1;

			const subdomain = window.location.hostname.split('.')[0];
			if (subdomain === 'findyourindependence') {
				// Render content for sub1
				registryId = 2;
			} else if (subdomain === 'philia') {
				// Render content for sub2
				registryId = 3;
			}

			const { token, provider: carePro } = await loginMutation.mutateAsync({
				email: values.email,
				password: values.password,
				registryId: registryId,
			});

			loginCarePro(token);
			Notification(notifications.auth.login);
			history.replace(coreRoutes.jobPosts);
		} catch (error) {
			console.error('Login failed', error);
			setServerError(error[0].message);
		}
	};

	const goToTOSPage = () => {
		history.push(coreRoutes.tos);
	};

	const goToPasswordResetPage = () => {
		history.push(authRoutes.passwordReset);
	};

	return (
		<StyledLoginPage>
			<PageHeading>Login</PageHeading>

			<LoginForm
				initialValues={{ email: '', password: '' }}
				loginHandler={loginHandler}
				serverError={serverError}
				goToTOSPage={goToTOSPage}
				goToPasswordResetPage={goToPasswordResetPage}
			/>
		</StyledLoginPage>
	);
};

export default LoginPage;
