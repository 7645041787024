import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const fetchSingleJobPost = async ({ jobPostId }) => {
	try {
		const data = await api.graph({
			query: `{
            fetchSingleJobPost(job_post_id: ${jobPostId}) {
                    ${JOB_POST_MODEL}
                }
            }`,
		});

		return data.fetchSingleJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchSingleJobPostQuery = (jobPostId) => {
	return useQuery({
		queryKey: ['fetchSingleJobPost', jobPostId],
		queryFn: () => {
			return fetchSingleJobPost({ jobPostId });
		},
		enabled: !!jobPostId,
	});
};
