import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import api from 'scripts/api';

import {} from 'reducers/graphqlModelTypes';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

import { PageContainer } from '../ModelDataViews/CoreView';
import PaymentDataView from '../ModelDataViews/PaymentDataView';
import CarePlanDataView from '../ModelDataViews/CarePlanDataView';

import CareProStripeStatusDataView from '../ModelDataViews/CareProStripeStatusDataView';
import CareProRegistryStatusDataView from '../ModelDataViews/CareProRegistryStatus';
import { Title, SubTitle, GridContainer, DataViewButton } from '../ModelDataViews/CoreView';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';
const CarePlan = (props) => {
	console.log('CareProHandler', props);
	const [carePlan, setCarePlan] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { history, location } = props;

	useEffect(() => {
		const carePlanId = location.pathname.split('/').pop();

		// ensure the payment_id is a number
		if (isNaN(carePlanId)) {
			toast.error('Invalid payment id', TOAST_ERROR_CONFIG);
		} else {
			fetchCarePlan(carePlanId);
		}
	}, []);

	const fetchCarePlan = async (carePlanId) => {
		try {
			const carePlanRes = await api.graph({
				query: `{
					registryFetchCarePlan(care_plan_id: ${carePlanId}) {
						completed_care_plan,
						completed_platform_onboard,
						completed_registry_onboard,
						registry_approval,
						documents_approval,
						cc_first_name,
						cc_last_name,
						cr_first_name,
						cr_last_name,
						cr_display_name,
						cc_phone_number,
						cc_relation_to_cr,
						cr_dob,
						cr_gender,
						care_zip,
						care_city,
						receiver_info {
							id
						},
						contact_info {
							id
						},
						location_info {
							id
						},
						needs_info {
							id
						},
					}
				}`,
			});

			setCarePlan(carePlanRes.registryFetchCarePlan);
			setIsLoading(false);
		} catch (err) {
			console.log('fetchUserCarePlan -> err', err);
			toast.error('Something went wrong please notify support', TOAST_ERROR_CONFIG);
		}
	};

	if (isLoading) {
		return <p>loading</p>;
	}

	if (!isLoading && carePlan === null) {
		return <p>Something went wrong</p>;
	}

	console.log('carePlan', carePlan);
	return (
		<PageContainer
			style={{
				position: 'relative',
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: '30px',
					left: '0px',
					cursor: 'pointer',
				}}
			>
				<Icon size='xl' icon={faChevronLeft} onClick={() => history.goBack()} />
			</div>
			<CarePlanDataView {...carePlan} />
			<Title>Care Plan Items</Title>
			<GridContainer columns={4}>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> Receiver Info</SubTitle>
					<DataViewButton
						onClick={() =>
							history.push(`/registry/formData/${carePlan.receiver_info.id}`)
						}
					>
						{' '}
						View
					</DataViewButton>
				</ColumnFlex>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> Contact Info</SubTitle>
					<DataViewButton
						onClick={() =>
							history.push(`/registry/formData/${carePlan.contact_info.id}`)
						}
					>
						{' '}
						View
					</DataViewButton>
				</ColumnFlex>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> Location Info</SubTitle>
					<DataViewButton
						onClick={() =>
							history.push(`/registry/formData/${carePlan.location_info.id}`)
						}
					>
						{' '}
						View
					</DataViewButton>
				</ColumnFlex>
				<ColumnFlex margin='0 0 24px 0 ' center fullWidth>
					<SubTitle> Care Needs Info</SubTitle>
					<DataViewButton
						onClick={() => history.push(`/registry/formData/${carePlan.needs_info.id}`)}
					>
						{' '}
						View
					</DataViewButton>
				</ColumnFlex>
			</GridContainer>
		</PageContainer>
	);
};

export default CarePlan;
