export const jobPostsRoutes = {
	home: '/client/job-posts',
	create: '/client/job-posts/create',
	jobPosts: `/client/job-posts/`,
	jobPostOffer: (jobPostId, careProId) =>
		`/client/job-posts/${jobPostId}/offer/care-pro/${careProId}`,
	viewOfferPage: (jobPostId, jobOfferId) =>
		`/client/job-posts/${jobPostId}/offer/view/${jobOfferId}`,
	jobPost: (jobPostId) => `/client/offers?job_post_id=${jobPostId}`,
};
