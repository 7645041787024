import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../corev2/utils';

export const StyledContainer = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	width: 30%;
`;

export const StyledSearchContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	max-width: 100%;
	padding: 12px;
`;

export const StyledSearchInput = styled.input`
	width: 90%;
	padding: 12px 40px 12px 16px;
	border: 1px solid ${colors.forms.placeholderColor};
	border-radius: 4px;
	font-size: 14px;
	outline: none;
	box-sizing: border-box;

	&::placeholder {
		color: ${colors.forms.placeholderColor};
	}
`;

export const StyledSearchIcon = styled.div`
	position: relative;
	padding: 12px;
	margin-left: 10px;
	color: ${colors.primary.primary};
	border: 1px solid ${colors.table.border};
	cursor: pointer;
`;

export const StyledMessageList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1px;
	background: ${colors.table.alternative};
	border-radius: 6px;
	overflow: hidden;
`;

export const StyledMessageItem = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 16px;
	background: ${colors.white};
	cursor: pointer;
	border-bottom: 1px solid ${colors.borderColor};
	color: ${colors.black};

	&:hover {
		background: ${colors.pageBackground};
	}
`;

export const StyledChevronIcon = styled.div`
	color: ${colors.gray};
	margin-left: auto;
`;

export const StyledAvatar = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: ${colors.table.border};
	flex-shrink: 0;
`;

export const StyledMessageContent = styled.div`
	flex: 1;
	min-width: 0;
`;

export const StyledMessageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
`;

export const StyledSenderName = styled.div`
	font-weight: 500;
	color: ${colors.black};
`;

export const StyledTimestamp = styled.div`
	color: ${colors.black};
	font-size: 14px;
`;

export const StyledMessagePreview = styled.div`
	color: ${colors.black};
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
`;
