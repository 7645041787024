export const carePlanRoutes = {
	home: '/client/care-plan',
	onboarding: {
		home: '/client/care-plan/onboarding',
		relationship: '/client/care-plan/onboarding/relationship',
		info: '/client/care-plan/onboarding/info',
		personal: '/client/care-plan/onboarding/personal',
		logistics: '/client/care-plan/onboarding/logistics',
		careNeeds: '/client/care-plan/onboarding/care-needs',
	},
	schedule: '/client/care-plan/schedule',
	management: {
		home: '/client/care-plan/management',
		info: '/client/care-plan/management/info',
		personal: '/client/care-plan/management/personal',
		logistics: '/client/care-plan/management/logistics',
		careNeeds: '/client/care-plan/management/care-needs',
	},
};
