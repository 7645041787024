import React, { Fragment, Component, useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import {
	addDocumentsRequirements,
	removeDocumentsRequirements,
	fetchClientDocumentRequirement,
	fetchCareProDocumentRequirement,
	createAttributeTrait,
	addTraitToTemplate,
	deactivateTraitFromTemplate,
} from 'reducers/admin';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

import Tabs from '../core/Tabs';

import {
	AgencyView,
	AgencyClientDocuments,
	AgencyCareProDocuments,
} from 'AppComp/admin/UserModel/UserModelBlocks';
import styled from 'styled-components';
import { device } from 'AppComp/styled/StyledMediaQuery';

export const ModelContainer = styled.div`
	margin: 16px 0 16px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 85vw;
	min-width: 435px;

	align-items: center;

	@media ${device.tablet} {
		width: 520px;
	}
`;

const ModelInnerCon = styled.div`
	background-color: white;
	border-radius: 8px;
	width: 100%;
`;

const initialTabState = {
	agency: {
		name: 'Agency',
		value: 'agency',
		selected: true, // Set this to `true` if it should be the default selected tab
	},
	client: {
		name: 'Client',
		value: 'client',
		selected: false,
	},
	carePro: {
		name: 'CarePro',
		value: 'carePro',
		selected: false,
	},
};

const AgencySettingsHandler = (props) => {
	const [tabOptions, setTabOptions] = useState(initialTabState);

	useEffect(() => {
		props.fetchClientDocumentRequirement();
		props.fetchCareProDocumentRequirement();
	}, []);

	const activeTab = Object.values(tabOptions).find((tab) => tab.selected)?.value || 'None';

	const renderTab = () => {
		switch (activeTab) {
			case 'agency':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<AgencyView />
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			case 'client':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<ColumnFlex>
									<AgencyClientDocuments
										addDocumentsRequirements={props.addDocumentsRequirements}
										removeDocumentsRequirements={
											props.removeDocumentsRequirements
										}
										clientDocumentRequirements={
											props.clientDocumentRequirements
										}
									/>
								</ColumnFlex>
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			case 'carePro':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<ColumnFlex>
									<AgencyCareProDocuments
										addDocumentsRequirements={props.addDocumentsRequirements}
										careProDocumentRequirements={
											props.careProDocumentRequirements
										}
										removeDocumentsRequirements={
											props.removeDocumentsRequirements
										}
									/>
								</ColumnFlex>
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			default:
				return <AgencyView />;
		}
	};

	const handleTabChange = (selectedTabName) => {
		const updatedTabs = Object.fromEntries(
			Object.entries(tabOptions).map(([key, option]) => [
				key,
				{ ...option, selected: option.name === selectedTabName },
			])
		);
		setTabOptions(updatedTabs);
	};

	return (
		<ColumnFlex alignCenter fullWidth fullHeight>
			<div
				style={{
					width: '435px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginTop: '1rem',
				}}
			>
				<div className='m-auto'>
					<Tabs options={tabOptions} selectHandler={handleTabChange} />
				</div>
				<RowFlex padding='0' gap='0 16px '>
					{renderTab()}
				</RowFlex>
			</div>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ admin }, props) => {
	return {
		agencyCarePlan: admin.agencyCarePlan,
		clientDocumentRequirements: admin.clientDocumentRequirements,
		careProDocumentRequirements: admin.careProDocumentRequirements,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		goToTab: (tab) => dispatch(push(`/registry/settings/${tab}`)),
		addDocumentsRequirements: (params) => dispatch(addDocumentsRequirements(params)),
		removeDocumentsRequirements: (params) => dispatch(removeDocumentsRequirements(params)),
		fetchClientDocumentRequirement: () => dispatch(fetchClientDocumentRequirement()),
		fetchCareProDocumentRequirement: () => dispatch(fetchCareProDocumentRequirement()),
		createAttributeTrait: (params) => dispatch(createAttributeTrait(params)),
		addTraitToTemplate: (params) => dispatch(addTraitToTemplate(params)),
		deactivateTraitFromTemplate: (params) => dispatch(deactivateTraitFromTemplate(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencySettingsHandler);
