import moment from 'moment';

export const formatIncomingShift = (incomingShift) => {
	return {
		id: incomingShift.id,
		jobId: incomingShift.job_id || null,
		scheduleId: incomingShift.schedule_id || null,
		title: 'Care Session',
		startTime: moment(incomingShift.start_time).format('MMMM D, YYYY [at] h:mm A'),
		endTime: moment(incomingShift.end_time).format('MMMM D, YYYY [at] h:mm A'),
	};
};
