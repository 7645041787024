export const authRoutes = {
	login: '/client/auth/login',
	passwordReset: '/client/auth/reset-password',
	onboarding: {
		home: '/client/auth/onboarding',
		personalInfo: '/client/auth/onboarding/personal',
		accountInfo: '/client/auth/onboarding/account',
		authCode: '/client/auth/onboarding/verify',
	},
};
