import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CAREPLAN_MODEL, SHIFT_MODEL } from '../../../core/api/models';

const fetchRegistryCarePlansForCarePros = async () => {
	try {
		const data = await api.graph({
			query: `
				query {
					fetchRegistryCarePlansForCarePros {

						${CAREPLAN_MODEL}

						shifts {
							${SHIFT_MODEL}
						}
					}
				}
			`,
		});

		return data.fetchRegistryCarePlansForCarePros;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchRegistryCarePlansForCareProsQuery = () => {
	return useQuery({
		queryKey: ['fetchRegistryCarePlansForCarePros'],
		queryFn: () => {
			return fetchRegistryCarePlansForCarePros();
		},
	});
};
