import React from 'react';

import { StyledFilterTab, StyledFilterTabText } from './styles';

const Tags = (props) => {
	const { options, selectHandler } = props;

	return (
		<>
			{options.map((option) => {
				return (
					<StyledFilterTab
						key={option.name}
						selected={option.selected}
						onClick={() => selectHandler(option.name, option.value)}
					>
						<StyledFilterTabText selected={option.selected}>
							{option.name}
						</StyledFilterTabText>
					</StyledFilterTab>
				);
			})}
		</>
	);
};

export default Tags;
