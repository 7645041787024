import React, { useState, useRef, useEffect } from 'react';
import Colors from 'colors';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { faTimes, faCog } from '@fortawesome/free-solid-svg-icons';

import { Hearts } from 'react-loader-spinner';
import Icon from 'AppComp/Icon';

import useFetchRegistryTemplate from '../api/query/useFetchRegistryTemplate';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
	FormDisplaySection,
	StyledFormPage,
	StyledPageHeadingContainer,
	PageHeading,
	FormItemsContainer,
	FormItem,
} from './styles';

import { TraitDisplay } from './styles/ManageTraitStyles';

import Text from 'AppComp/Text';

import { SectionDisplay, InnerSectionDisplay, SubmitButton } from './styles/FormGen';

import { AddSection, EditSection } from './ManageSection';
import { AddTrait } from './ManageTrait';

import { useFormTemplateContext, ACTIONS } from '../context';
import useEditSection from '../api/mutations/useEditSection';

const Section = (props) => {
	const { id, moveSection, label, index, traits, traitTemplateId } = props;
	const [showAddTrait, setShowAddTrait] = useState(false);
	const [showEditSection, setShowEditSection] = useState(false);

	const ref = useRef(null);

	const [, drop] = useDrop({
		accept: 'SECTION',
		hover(item) {
			if (item.index !== index) {
				//moveSection(id, item.index);
				//item.index = index;
			}
		},
		drop: (item) => {
			console.log('item', item);
			if (item.index !== index) {
				console.log(label);
				moveSection(item.id, item.index - 1);
			}
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'SECTION',
		item: { id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	const renderTraits = () => {
		const { traits } = props;

		console.log('traits', traits);
		if (traits.length === 0) {
			return (
				<p
					style={{
						textAlign: 'center',
					}}
				>
					No Questions/Add a question to get started
				</p>
			);
		} else {
			// filter out all the trait that are the status is "ARCHIVED"

			return traits
				.filter((trait) => trait.status === 'PLATFORM_ACTIVE')
				.map((trait) => {
					return <TraitDisplay key={trait.id} trait={trait} />;
				});
		}
	};
	return (
		<>
			<AnimatePresence>
				{showAddTrait && (
					<AddTrait
						sectionId={id}
						traitTemplateId={traitTemplateId}
						isOpen={showAddTrait}
						setShowAddTrait={setShowAddTrait}
						traits={traits}
					/>
				)}
			</AnimatePresence>

			<AnimatePresence>
				{showEditSection && (
					<EditSection
						sectionId={id}
						traitTemplateId={traitTemplateId}
						isOpen={showEditSection}
						setShowEditSection={setShowEditSection}
						section_label={label}
					/>
				)}
			</AnimatePresence>

			<SectionDisplay
				ref={ref}
				style={{
					opacity: isDragging ? 0.5 : 1,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Text fontWeight='500' color={Colors.primary.primary} largeText>
						{label !== 'NO_NAME' ? label : ''}
					</Text>
					{label !== 'NO_NAME' && (
						<div
							style={{
								cursor: 'pointer',
							}}
							onClick={() => setShowEditSection(true)}
						>
							<Icon icon={faCog} color='black' />
						</div>
					)}
				</div>

				<InnerSectionDisplay>{renderTraits()}</InnerSectionDisplay>
				<SubmitButton
					onClick={() => {
						setShowAddTrait(true);
					}}
					style={{
						margin: 'auto',
					}}
				>
					<p>Add a Question</p>
				</SubmitButton>
			</SectionDisplay>
		</>
	);
};
export const FormTemplate = () => {
	const history = useHistory();
	const location = useLocation();
	const { formId } = useParams();

	const { state, dispatch } = useFormTemplateContext();

	const { formTemplate, loading, error } = state;
	const [showAddSection, setShowAddSection] = useState(false);

	const { data, isLoading } = useFetchRegistryTemplate(formId);
	console.log('formTemplate', formTemplate);

	const editSection = useEditSection();

	useEffect(() => {
		if (data) {
			dispatch({ type: ACTIONS.SET_FORM_TEMPLATE, payload: data });
		}
	}, [data, isLoading, dispatch]);

	if (isLoading) {
		return (
			<StyledFormPage>
				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Hearts color={Colors.primary.primary} height={100} width={100} />
				</div>
			</StyledFormPage>
		);
	}

	if (!formTemplate) {
		return (
			<StyledFormPage>
				<Text>Form not found</Text>
			</StyledFormPage>
		);
	}

	const renderSection = () => {
		const sections = formTemplate.sections;

		// order section by order_index property

		sections.sort((a, b) => a.order_index - b.order_index);

		return sections.map((section, index) => {
			return (
				<Section
					id={section.id}
					{...section}
					index={index}
					moveSection={moveSection}
					key={section.id}
					traits={section.traits}
					traitTemplateId={formTemplate.id}
				/>
			);
		});
	};

	const moveSection = async (sectionId, toIndex) => {
		console.log('sectionId', sectionId);
		console.log('toIndex', toIndex);
		try {
			const updatedSections = await editSection.mutateAsync({
				sectionId,
				selected_index: toIndex,
			});

			// dispatch the updated event
			dispatch({
				type: ACTIONS.SET_FORM_TEMPLATE,
				payload: {
					...formTemplate,
					sections: updatedSections,
				},
			});
		} catch (err) {
			console.log('moveSection', err);
		}

		//setSections(updatedSections);
	};

	console.log('0x01', formTemplate.sections);

	return (
		<>
			<AnimatePresence>
				{showAddSection && (
					<AddSection
						traitTemplateId={formTemplate.id}
						setShowAddSection={setShowAddSection}
						isOpen={showAddSection}
					/>
				)}
			</AnimatePresence>

			<DndProvider backend={HTML5Backend}>
				<StyledFormPage
					style={{
						paddingBottom: '20px',
					}}
				>
					<StyledPageHeadingContainer>
						<PageHeading styles={{ padding: '20px 0 0 0' }}>
							{formTemplate.template_label}
						</PageHeading>
					</StyledPageHeadingContainer>
					<Text style={{ textAlign: 'center' }} center fontWeight='300' largeText>
						Add questions to section or add another section to the form
					</Text>

					<SubmitButton
						onClick={() => {
							setShowAddSection(true);
						}}
						style={{
							margin: '0 auto',
						}}
					>
						<p>Add a Section</p>
					</SubmitButton>
					{renderSection()}
				</StyledFormPage>
			</DndProvider>
		</>
	);
};

export default FormTemplate;
