import { useQuery } from '@tanstack/react-query';

import api from '../../utility/graphQL';

import { SHIFT_MODEL } from '../models';

const fetchCarePlanShifts = async () => {
	try {
		const data = await api.graph({
			query: `{
            fetchCarePlanShifts {
					${SHIFT_MODEL}
                }
            }`,
		});

		return data.fetchCarePlanShifts;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCarePlanShiftsQuery = () => {
	return useQuery({
		queryKey: ['fetchCarePlanShifts'],
		queryFn: () => {
			return fetchCarePlanShifts();
		},
	});
};
