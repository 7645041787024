import React from 'react';

import { StyledText } from './styles';

const Text = ({ children, size, ...restProps }) => {
	return (
		<StyledText size={size} {...restProps}>
			{children}
		</StyledText>
	);
};

export default Text;

export const TextStyled = ({ children, size, ...restProps }) => {
	return (
		<StyledText {...restProps} size={size}>
			{children}
		</StyledText>
	);
};
