import React from 'react';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledLocation,
	StyledDescriptionContainer,
	StyledGender,
	StyledDescription,
	StyledButtonContainer,
	StyledButton,
} from '../styles';

import { capitalizeFirstLetter } from 'scripts';

const JobPostTile = (props) => {
	const { jobPost, onJobPostClick } = props;
	// const { carePlan } = jobPost;
	// const { display_name } = carePlan;

	const { id, budget_low, budget_high, carePlan } = jobPost;
	const { display_name, gender, care_type, medical_notes } = carePlan;

	console.log(jobPost, props);

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{`${display_name}`}</StyledName>
					</StyledPersonalInfo>
					<StyledRate>
						${`${budget_low} - ${budget_high}`} <StyledHour>/ hr </StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				{/* <StyledLocation>Lake Raymondport</StyledLocation> */}
				<StyledDescriptionContainer>
					<StyledGender>
						{capitalizeFirstLetter(gender)} |{' '}
						{care_type === 'hourly' ? 'Hourly' : 'Live In'}
					</StyledGender>
					<StyledDescription>{medical_notes}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					<StyledButton onClick={() => onJobPostClick(id)}>Apply</StyledButton>
					{/* <StyledButton isTransparent onClick={() => careProClickHandler(id)}>
						View
					</StyledButton> */}
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default JobPostTile;
