import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const updateConversationAction = async ({ conversationId, archived }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				updateConversationAction(
					conversation_id: ${conversationId},
					user_type: "client",
                    archived: ${archived ? archived : false}
				) {
					id
				},
			}`,
		});

		return data.updateConversationAction;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateConversationActionMutation = () => {
	return useMutation({
		mutationKey: ['updateConversationAction'],
		mutationFn: (values) => {
			return updateConversationAction(values);
		},
	});
};
