import React from 'react';

import { FieldGroup, Label, Slider, Select } from '../../../../../corev2/Forms';
import Text from '../../../../../corev2/Text';
import Tags from '../../../../../corev2/Tags';

import {
	StyledOffersFilter,
	StyledTagsWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
} from './styles';

const OffersFilter = (props) => {
	const { stats, changeSearchParam } = props;

	const options = Object.values(stats).map((stat, index) => {
		return {
			name: `${stat.title} (${stat.stat})`,
			selected: stat.isActive,
			value: stat.param,
		};
	});

	return (
		<StyledOffersFilter>
			<StyledFilterLabelWrapper>
				<StyledLabel>Care Pros & Offers</StyledLabel>
				<Text>
					Filter betweeen all availale Care Pros in your registry and all the offers you
					have received or made.
				</Text>
			</StyledFilterLabelWrapper>

			<StyledTagsWrapper>
				<Tags options={options} selectHandler={changeSearchParam} />
			</StyledTagsWrapper>
			{/* 
			<FieldGroup>
				<Label> Filter by Offer posts</Label>
				<Select>
					<option>Something</option>
				</Select>
			</FieldGroup>

			<FieldGroup>
				<Label> Filter by Care Pros</Label>
				<Select>
					<option>Something</option>
				</Select>
			</FieldGroup> */}
			{/* 
			<Slider
				label='Price Range'
				minAllow={5}
				maxAllow={50}
				step={5}
				minValue={5}
				maxValue={50}
				handler={() => {}}
			/> */}
		</StyledOffersFilter>
	);
};

export default OffersFilter;
