import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Tabs from '../../../../../corev2/Tabs';
import Text from '../../../../../corev2/Text';
import ConversationItem from '../ConversationItem';

import {
	StyledContainer,
	StyledTabsContainer,
	StyledHeading,
	StyledSearchContainer,
	StyledSearchInput,
	StyledSearchIcon,
	StyledConversationsList,
} from './styles';

const tabOptions = {
	messages: {
		name: 'Messages',
		selected: true,
	},
	archvied: {
		name: 'Archived',
		selected: false,
	},
};

const Chat = (props) => {
	const { onClick, chatId, archiveChatHandler, conversations = [] } = props;
	const [chatTabs, setChatTabs] = useState(tabOptions);

	const handleTabChange = (name) => {
		const updatedTabs = Object.fromEntries(
			Object.entries(tabOptions).map(([key, tab]) => [
				key,
				{ ...tab, selected: tab.name === name },
			])
		);
		setChatTabs(updatedTabs);
	};

	return (
		<StyledContainer>
			<StyledTabsContainer>
				<StyledHeading>Inbox</StyledHeading>

				<Tabs options={chatTabs} selectHandler={handleTabChange} />
			</StyledTabsContainer>

			<StyledSearchContainer>
				<StyledSearchIcon>
					<FontAwesomeIcon icon={faSearch} />
				</StyledSearchIcon>

				<StyledSearchInput placeholder='Search Messages' />
			</StyledSearchContainer>

			<StyledConversationsList>
				{conversations.filter((conversation) => {
					const isMessagesTabSelected = chatTabs.messages.selected;
					return isMessagesTabSelected
						? !conversation.conversationAction?.archived
						: conversation.conversationAction?.archived;
				}).length > 0 ? (
					conversations
						.filter((conversation) => {
							const isMessagesTabSelected = chatTabs.messages.selected;
							return isMessagesTabSelected
								? !conversation.conversationAction?.archived
								: conversation.conversationAction?.archived;
						})
						.map((conversation) => (
							<ConversationItem
								key={conversation.id}
								conversation={conversation}
								chatId={chatId}
								onClick={onClick}
								isArchived={
									conversation.conversationAction
										? conversation.conversationAction.archived
										: false
								}
								archiveChatHandler={archiveChatHandler}
							/>
						))
				) : (
					<Text size='large'>
						{chatTabs.messages.selected
							? 'No active conversations found'
							: 'No archived conversations found'}
					</Text>
				)}
			</StyledConversationsList>
		</StyledContainer>
	);
};

export default Chat;
