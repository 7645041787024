import React, { useState, useEffect } from 'react';

import NavTabs from '../../corev2/NavTabs/components';
import DescriptivePage from '../../corev2/DescriptivePage';
import Text from '../../corev2/Text';
import Loader from '../../corev2/Loader';
import DocumentsList from '../../carePro/modules/documents/components/DocumentsList';
import UploadTile from '../../carePro/modules/documents/components/UploadTile';

import uploadFilesToAWSS3 from '../../carePro/modules/documents/utils/uploadFilesToAWSS3';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import {
	StyledDocumentsContainer,
	StyledNavContainer,
	StyledDocumentsWrapper,
	StyledDocumentRequest,
} from '../../carePro/modules/documents/pages/DocumentsPage/styles';

import api from 'scripts/api';
import { DOCUMENT_MODEL, DOCUMENT_REQUIREMENTS } from '../../../reducers/graphqlModelTypes';

const NAV_ITEMS = {
	requirements: { text: 'Requirements', stat: 0, active: true },
	pending: { text: 'Pending', stat: 0, active: false },
	approved: { text: 'Approved', stat: 0, active: false },
	rejected: { text: 'Rejected', stat: 0, active: false },
};

const DocumentPage = (props) => {
	const { history, location } = props;

	const [navItems, setNavItems] = useState(NAV_ITEMS);
	const [filteredDocumentRequirements, setFilteredDocumentRequirements] = useState([]);
	const [uploadedDocumentStates, setUploadedDocumentStates] = useState({}); // To manage loading states per document
	const [pendingDocuments, setPendingDocuments] = useState([]);
	const [approvedUploadedDocuments, setApprovedUploadedDocuments] = useState([]);
	const [rejectedUploadedDocuments, setRejectedUploadedDocuments] = useState([]);

	const [careProId, setCareProId] = useState(null);

	useEffect(() => {
		const careProId = location.pathname.split('/').pop();

		console.log('careProId', careProId);
		// ensure the payment_id is a number
		if (isNaN(careProId)) {
			//toast.error('Invalid payment id', TOAST_ERROR_CONFIG);
		} else {
			const careProIdNum = parseInt(careProId);
			fetchInitData(careProIdNum);
			setCareProId(careProIdNum);
			// fetchUploadedDocuments(careProIdNum);
			// fetchDocumentRequirements(careProIdNum);
		}
	}, []);

	const fetchInitData = async (careProId) => {
		try {
			const uploadedDocuments = await fetchUploadedDocuments(careProId);

			console.log('uploadedDocuments', uploadedDocuments);
			const documentRequirements = await fetchDocumentRequirements(careProId);

			console.log('documentRequirements', documentRequirements);
			if (uploadedDocuments && documentRequirements) {
				// Separate uploaded documents into different states
				const pendingDocuments = uploadedDocuments.filter(
					(doc) => doc.status === 'pending'
				);
				const approvedDocuments = uploadedDocuments.filter(
					(doc) => doc.status === 'approved'
				);
				const rejectedDocuments = uploadedDocuments.filter(
					(doc) => doc.status === 'rejected'
				);

				setPendingDocuments(pendingDocuments);
				setApprovedUploadedDocuments(approvedDocuments);
				setRejectedUploadedDocuments(rejectedDocuments);

				// Filter document requirements excluding approved and pending documents
				const filteredRequirements = documentRequirements.filter(
					(req) =>
						!uploadedDocuments.some(
							(doc) =>
								doc.documentRequirement.id === req.id &&
								(doc.status === 'approved' || doc.status === 'pending') // Exclude if pending or approved
						)
				);

				// Map rejected documents to their requirements if there are no pending or approved documents for the same request
				const rejectedRequirements = rejectedDocuments
					.filter(
						(rejectedDoc) =>
							pendingDocuments.every(
								(pendingDoc) =>
									pendingDoc.documentRequirement.id !==
									rejectedDoc.documentRequirement.id
							) &&
							approvedDocuments.every(
								(approvedDoc) =>
									approvedDoc.documentRequirement.id !==
									rejectedDoc.documentRequirement.id
							) // Include only if no pending or approved document exists for the same request
					)
					.map((doc) => doc.documentRequirement)
					.filter(
						(req) =>
							!filteredRequirements.some((filteredReq) => filteredReq.id === req.id) // Avoid duplicates
					);

				const filteredDocumentRequirements = [
					...filteredRequirements,
					...rejectedRequirements,
				];
				// Combine filtered requirements and rejected requirements
				setFilteredDocumentRequirements(filteredDocumentRequirements);

				setNavItems((prev) => ({
					...prev,
					requirements: {
						...prev.requirements,
						stat: filteredDocumentRequirements.length,
					},
					pending: { ...prev.pending, stat: pendingDocuments.length },
					approved: { ...prev.approved, stat: approvedUploadedDocuments.length },
					rejected: { ...prev.rejected, stat: rejectedUploadedDocuments.length },
				}));
			}
		} catch (err) {
			console.error(err);
		}
	};
	const fetchUploadedDocuments = async (careProId) => {
		try {
			const careProDocsRes = await api.graph({
				query: `{
                fetchRegistryCarePro(provider_id: ${careProId}) {
                  documents {
                    ${DOCUMENT_MODEL}
                  }
                }
              }`,
			});

			const uploadedDocuments = careProDocsRes.fetchRegistryCarePro.documents;

			return uploadedDocuments;
		} catch (err) {
			console.error(err);
		}
	};

	const fetchDocumentRequirements = async (careProId) => {
		try {
			const documentRequirementsRes = await api.graph({
				query: `{
              registryFetchCareProDocumentRequirement {
                ${DOCUMENT_REQUIREMENTS}
              }
            }`,
			});

			const documentRequirements =
				documentRequirementsRes.registryFetchCareProDocumentRequirement;

			return documentRequirements;
		} catch (err) {
			console.error(err);
		}
	};

	const careProUploadDocument = async ({ documentUrls, requiredDocumentId, expirationDate }) => {
		try {
			const query = `mutation {
            uploadDocumentForCarePro(
              document_urls: ${api.graphStringify(documentUrls)}
              required_document_id: ${requiredDocumentId}
              care_pro_id: ${careProId}
              ${expirationDate ? `expiration_date: "${expirationDate}"` : ''}
            ) {
              ${DOCUMENT_MODEL}
            }
          }`;

			const data = await api.graph({ query });

			return data.careProUploadDocument;
		} catch (err) {
			console.error(err);
			throw err;
		}
	};

	const handleUploadDocuments = async (file, documentRequirementId, expirationDate) => {
		setUploadedDocumentStates((prev) => ({
			...prev,
			[documentRequirementId]: { loading: true }, // Set loading state for the document
		}));

		try {
			// Upload the file to AWS S3
			const uploadedFile = await uploadFilesToAWSS3({ [documentRequirementId]: { file } });

			// Send the uploaded file URL and metadata to the backend
			await careProUploadDocument({
				documentUrls: [uploadedFile[documentRequirementId].url], // Use the uploaded file's URL
				requiredDocumentId: documentRequirementId,
				expirationDate: expirationDate || null, // Include expiration date if provided
			});

			// Update the state to indicate success
			setUploadedDocumentStates((prev) => ({
				...prev,
				[documentRequirementId]: { loading: false, success: true },
			}));

			// Refetch document data to update the UI
			fetchInitData(careProId);
		} catch (error) {
			console.error('Document upload failed:', error);

			// Update the state to indicate failure
			setUploadedDocumentStates((prev) => ({
				...prev,
				[documentRequirementId]: { loading: false, success: false },
			}));
		}
	};

	const getDescriptionText = () => {
		if (navItems.requirements.active) {
			return (
				<Text size='large'>
					Requirements are the documents that you need to upload, including those rejected
					by the registry. Check the notes of rejected documents to understand what you
					need to do.
				</Text>
			);
		}
		if (navItems.pending.active) {
			return (
				<Text size='large'>
					Pending documents are those that you have uploaded and are currently under
					review by the registry.
				</Text>
			);
		}
		if (navItems.approved.active) {
			return (
				<Text size='large'>
					Approved documents are those that have been reviewed and approved by the
					registry.
				</Text>
			);
		}
		if (navItems.rejected.active) {
			return (
				<Text size='large'>
					Rejected documents are those that have been reviewed by the registry but did not
					meet the requirements. Please check the notes for feedback and upload the
					corrected documents.
				</Text>
			);
		}
		return null;
	};

	return (
		<DescriptivePage heading='Document Requests' descriptionContent={getDescriptionText()}>
			<StyledDocumentsContainer>
				<StyledNavContainer>
					<NavTabs
						items={navItems}
						navTabsHandler={(key) =>
							setNavItems((prev) =>
								Object.entries(prev).reduce((acc, [k, item]) => {
									acc[k] = { ...item, active: k === key };
									return acc;
								}, {})
							)
						}
					/>
				</StyledNavContainer>

				{navItems.requirements.active && (
					<StyledDocumentsWrapper>
						<StyledDocumentRequest>
							{filteredDocumentRequirements.map((req) => (
								<UploadTile
									key={req.id}
									documentRequirement={req}
									loading={uploadedDocumentStates[req.id]?.loading || false}
									onUpload={(file, expirationDate) =>
										handleUploadDocuments(file, req.id, expirationDate)
									}
								/>
							))}

							{filteredDocumentRequirements.length === 0 && (
								<Text>No document requirements.</Text>
							)}
						</StyledDocumentRequest>
					</StyledDocumentsWrapper>
				)}

				{navItems.pending.active && (
					<DocumentsList title='Pending Documents' documents={pendingDocuments} />
				)}

				{navItems.approved.active && (
					<DocumentsList
						title='Approved Documents'
						documents={approvedUploadedDocuments}
					/>
				)}

				{navItems.rejected.active && (
					<DocumentsList
						title='Rejected Documents'
						documents={rejectedUploadedDocuments}
					/>
				)}
			</StyledDocumentsContainer>
		</DescriptivePage>
	);
};

export default DocumentPage;
