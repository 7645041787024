import styled from 'styled-components';

import { colors } from '../utils';

export const StyledFilterTab = styled.div`
	border: 1px solid ${(props) => (props.selected ? colors.primary.primary : colors.gray)};
	border-radius: 5px;
	display: flex;
	padding: 5px 15px;
	cursor: pointer;
	align-items: center;

	float: left;

	&:hover span {
		color: ${colors.primary.primary};
	}

	&:hover {
		border: 1px solid ${colors.primary.primary};
	}
`;

export const StyledFilterTabText = styled.span`
	font-size: 14px;
	color: ${({ selected }) => (selected ? colors.primary.primary : colors.gray)};
`;
