import React from 'react';

const PrivacyNotice = () => {
	return (
		<div className='privacy-notice-container'>
			<h1>KPPD Privacy Notice</h1>
			<p>
				<strong>Last Updated/Effective Date:</strong> 7/30/24
				<br />
				<br />
				<br />
			</p>

			<section>
				<p>
					KPPD Software Dev LLC and its affiliates and subsidiaries (“KPPD,” “we,” “us,”
					or “our”) respect your privacy. This Privacy Notice describes the processing of
					Personal Information (defined below) that is provided, collected, or disclosed
					in the course of providing our products or services to you, including our
					proprietary verification and matching platform and related services
					(“Services”). This Privacy Notice is also applicable to any other platforms that
					link to this Privacy Notice. It also describes rights you may have under
					applicable laws.
				</p>
				<br />
				This Privacy Notice is available to consumers with disabilities. To access this
				Privacy Notice in an alternative downloadable format, please click here.
				<br />
				<br />
			</section>

			<section>
				<h2>Personal Information We Collect</h2>
				<p>
					We may collect a range of Personal Information. “Personal Information” means
					information that uniquely identifies, relates to, describes, or is reasonably
					capable of being associated with or linked to you.
					<br />
					<br />
					The types of Personal Information we collect may include:
				</p>
				<ul>
					<li>
						<strong>Contact Information:</strong> If you submit an inquiry, register for
						an account, or provide information on or through our Services, we may
						collect your contact information. This may include your name, address, email
						address, demographic information, and phone number.
					</li>
					<br />
					<li>
						<strong>Application and Licensure Information:</strong> In order for an
						agency or registry to verify your credentials and experience, we may collect
						your resume, photo, qualifications, assessments, vaccinations, and
						professional credentials/licensure, and any additional information that may
						be contained in background checks and drug screenings. We may collect
						certain information in order to verify your identity, such as your Social
						Security number and date of birth. We may also collect Personal Information
						about the job or shift you have accepted or declined. .
					</li>
					<br />
					<li>
						<strong>Business Information:</strong> If you are an individual using the
						Services on behalf of an agency or registry, we may collect information
						about your role within the agency or registry, including your name, title,
						and employer.
					</li>
					<br />
					<li>
						<strong>Geolocation Data:</strong> Once you have indicated that you have
						started a shift, geolocation data will be collected in order to verify your
						location.
					</li>
					<br />
					<li>
						<strong>Preferences:</strong> When using the Services, we may collect
						information related to your shifts, schedules, and availability.
					</li>
					<br />
					<li>
						<strong>Banking Information:</strong> In order for us to submit payments to
						you on behalf of an agency or registry, we may use third-party service
						providers who may collect your banking information, including the name of
						your bank and applicable account and routing numbers.
					</li>
					<br />
					<li>
						<strong>Usage Information:</strong> When you use the Services, we may
						automatically record information, including your Internet Protocol address
						(IP Address), geolocation of your device, browser type, referring URLs
						(e.g., the website you visited before coming to our website), domain names
						associated with your internet service provider, and any other information
						regarding your interaction with our website.
					</li>
					<br />
					<li>
						<strong>Communication Information:</strong> We may collect Personal
						Information contained within your communications with us, including audio,
						electronic, or visual information, screen sharing views, and any files
						provided to us via email, chat functionality, social media, telephone, or
						otherwise. Where permitted by applicable law, we may collect and maintain
						records of calls and chats with our agents, representatives, or employees
						via message, chat, post, or similar functionality.
					</li>
				</ul>
			</section>

			<section>
				<h2>How We Collect Personal Information</h2>
				<br />
				<ul>
					<li>
						<strong>Directly From You:</strong> We collect Personal Information that you
						provide to us, for example, if you choose to contact us, choose to be
						matched with an agency or registry through the Services, request information
						from us, or sign up to receive updates.
					</li>
					<br />
					<li>
						<strong>From Third Parties:</strong> We may collect Personal Information
						from third parties including, business partners, job sites, subcontractors,
						advertising networks, analytics providers, and search information providers,
						who may provide us with Personal Information about you.
					</li>
					<br />
					<li>
						<strong>Through Online Tracking Technologies:</strong> We use cookies and
						similar technologies to collect Personal Information related to activity on
						our website. For additional information regarding our use of these
						technologies, see the Cookies and Tracking Technologies section below.
					</li>
					<br />
				</ul>
			</section>

			<section>
				<h2>How We Use Personal Information</h2>
				<br />
				<p>To the extent permitted by applicable law, we use Personal Information:</p>
				<ul>
					<li>
						To provide and personalize our Services, such as providing our Services,
						processing payments, providing customer service, maintaining or servicing
						accounts, verifying/validating your Personal Information, creating and
						maintaining business records, verifying eligibility, and undertaking or
						providing similar services.
					</li>
					<br />

					<li>
						For internal research and development, such as testing and verifying the
						quality of our Services, improving the quality of our Services, and creating
						new Services.
					</li>
					<br />

					<li>
						For marketing, such as sending information about our Services, including
						using your information to send you messages, notices, newsletters, surveys,
						promotions, or news about events.
					</li>
					<br />

					<li>
						For communicating with you, such as responding to your questions and
						comments.
					</li>
					<br />

					<li>
						For legal, security, or safety reasons, such as protecting our and our
						users’ safety, property, or rights; complying with legal requirements;
						enforcing our terms, conditions, and policies; detecting, preventing, and
						responding to security incidents; and protecting against malicious,
						deceptive, fraudulent, or illegal activity.
					</li>
					<br />

					<li>
						As part of a corporate transaction, such as in connection with the sale of
						part or all of our assets or business, the acquisition of part or all of
						another business or another business’ assets, or another corporate
						transaction, including bankruptcy.
					</li>
				</ul>
			</section>

			<section>
				<h2>How We Disclose Personal Information</h2>
				<ul>
					<li>
						Service Providers – We disclose your Personal Information with third party
						service providers as necessary to enable them to support our Services or
						other aspects of our business. This includes third parties that assist us
						with verifying your professional credentials/licensure, performing
						background checks and drug screens, and those that help us process payments.
					</li>
					<br />
					<li>
						Agencies and Registries – In order to provide our Services, we disclose
						Personal Information to agencies and registries in order to enable the
						matching process.
					</li>
					<br />
					<li>
						Affiliates and Subsidiaries – We may disclose your information to the KPPD
						family of companies for their and our business purposes and for marketing
						purposes, including to provide you information about the products or
						services we think may interest you.
					</li>
					<br />
					<li>
						Legal Obligation or Safety Reasons – We may disclose Personal Information to
						a third party when we have a good faith belief that such disclosure of
						Personal Information is reasonably necessary to (a) satisfy or comply with
						any requirement of law, regulation, legal process, or enforceable
						governmental request, (b) enforce or investigate a potential violation of
						any agreement you have with us, (c) detect, prevent, or otherwise respond to
						fraud, security or technical concerns, (d) support auditing and compliance
						functions, or (e) protect the rights, property, or safety of KPPD, its
						employees and clients, or the public against harm.
					</li>
					<br />
					<li>
						Merger or Change of Control – We may disclose Personal Information to third
						parties as necessary if we are involved in a merger, acquisition, or any
						other transaction involving a change of control in our business, including
						but not limited to, a bankruptcy or similar proceeding. Where legally
						required, we will give you prior notice prior to such disclosure.
					</li>
				</ul>
			</section>

			<section>
				<h2>Cookies and Tracking Technologies</h2>
				<br />
				<p>
					We and our service providers may use cookies and similar technologies to collect
					usage and browser information about how you use our website. We process the
					information collected through such technologies, which may include or be
					combined with Personal Information, to help operate certain features of our
					website, to enhance your experience through personalization, and to help us
					better understand the features of our website that you and other users are most
					interested in.
				</p>
				<br />
				<p>
					We may allow or enable third parties to collect Personal Information in order to
					provide their interest-based advertising services, and we may use Google Ads and
					other third parties to serve ads for our Services across various websites. To
					opt-out of this type of advertising by Google, to customize your ad preferences,
					or to limit Google’s collection or use of your data, visit Google’s Safety
					Center and Google’s Ad Settings and follow Google’s personalized ad opt-out
					instructions. Opting out will not affect your use of our Services.
				</p>
				<br />
				<p>
					We use analytics services, including Google Analytics, to assist us with
					analyzing our website traffic. These services utilize cookies and other
					technologies that collect your Personal Information. To learn more about how
					Google uses data, visit Google’s Privacy Policy and Google’s page on “How Google
					uses data when you use our partners’ websites or apps.” You may download the
					Google Analytics Opt-out Browser Add-on for each web browser you use, but this
					does not prevent the use of other analytics tools. To learn more about Google
					Analytics cookies, visit Google Analytics Cookie Usage on Websites.
				</p>
				<br />
				<p>
					In order to manage your preferences with respect to these technologies, most
					browsers provide you with the ability to block, delete, or disable cookies, and
					your mobile device may allow you to disable transmission of unique identifiers
					and location data. If you choose to reject cookies or block device identifiers,
					some features of our Services may not be available, or some functionality may be
					limited or unavailable. Please review the help pages of your browser or mobile
					device for assistance with changing your settings. We do not respond to Do Not
					Track (“DNT”) signals sent to us by your browser at this time.
				</p>
				<br />
			</section>

			<section>
				<h2>How Long We Keep Your Personal Information</h2>
				<br />
				<p>
					We will retain each category of your Personal Information for as long as
					necessary to fulfill the purposes described in this Privacy Notice, unless
					otherwise required by applicable laws. Criteria we will use to determine how
					long we will retain your Personal Information include whether we need your
					Personal Information to provide you with our Services you have requested; we
					continue to have a relationship with you; you have requested information or
					Services from us; we have a legal right or obligation to continue to retain your
					Personal Information; we have an obligation to a third party that involves your
					Personal Information; our retention or recordkeeping policies and obligations
					dictate that we retain your Personal Information; we have an interest in
					providing you with Personal Information about our Services; or we have another
					business purpose for retaining your Personal Information.
				</p>
				<br />
			</section>

			<section>
				<h2>How We Protect Personal Information</h2>
				<br />
				<p>
					We use commercially reasonable efforts to protect the confidentiality and
					security of Personal Information. However, despite these efforts to store
					Personal Information in a secure environment, we cannot guarantee the security
					of Personal Information during its transmission or its storage on our systems.
				</p>
				<br />
			</section>

			<section>
				<h2>International Use of our Services</h2>
				<br />
				<p>
					Our website is hosted in the United States. If you are visiting our website from
					outside of the United States, please note that by providing your Personal
					Information, it is being transferred to, stored, collected, or processed in the
					United States, where our servers are located and operated.
				</p>
				<br />
			</section>

			<section>
				<h2>Children’s Privacy </h2>
				<br />
				<p>
					We do not knowingly collect or solicit any Personal Information from children
					under the age of 13 without verified written parental consent. In the event that
					we learn that we have collected Personal Information from a child under the age
					of 13, we will promptly take steps to delete that information. If you are a
					parent or legal guardian and think your child has given us their Personal
					Information, you can email us at support@fyi.care.
				</p>
				<br />
			</section>

			<section>
				<h2>Links to Third-Party Websites</h2>
				<br />
				<p>
					We are not responsible for the practices employed by any websites or services
					linked to or from our Services, including the information or content contained
					within them. We encourage you to investigate and ask questions before disclosing
					Personal Information to third parties, since any Personal Information disclosed
					will handled in accordance with the applicable third party’s privacy notice.
				</p>
				<br />
			</section>

			<section>
				<h2>Your Privacy Rights and How to Exercise Them </h2>
				<br />
				<p>
					Depending on where you live, you may have the right to exercise certain controls
					and choices regarding our collection, use, and disclosure of your Personal
					Information. If you believe you may the right to exercise certain controls and
					choices, please submit a request to support@fyi.care. Please note that we may
					need to verify your identity before considering a request and that we may deny
					certain requests, or only fulfill some in part, as permitted or required by law.
				</p>
				<br />
				<p>
					To opt out of marketing emails, please email us at support@fyi.care or by
					following the instructions included in the email or text correspondence. Please
					note that, even if you unsubscribe from certain correspondence, we may still
					need to contact you with important transactional or administrative information,
					as permitted by law. Additionally, if you withdraw your consent or object to
					processing of your Personal Information, or if you choose not to provide certain
					Personal Information, we may be unable to provide some or all of our Services to
					you.
				</p>
				<br />
			</section>

			<section>
				<h2>Changes to this Privacy Notice </h2>
				<br />
				<p>
					Please note that we may modify or update this Privacy Notice from time to time,
					so please review it periodically. We will provide you notice if material changes
					are made. Unless otherwise indicated, any changes to this Privacy Notice will
					apply immediately upon posting to our websites.
				</p>
				<br />
			</section>

			<section>
				<h2>Contact Us</h2>
				<br />
				<p>
					If you have any questions about our practices or this Privacy Notice, please
					contact us at support@fyi.care. You may also write to us at [address].
				</p>
			</section>
		</div>
	);
};

export default PrivacyNotice;
