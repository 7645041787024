import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import CreateJobPostForm from '../../components/CreateJobPostForm';

import { useAuth } from '../../../core/hooks/useAuth';
import { useCreateJobPostMutation } from '../../api/mutations/useCreateJobPostMutation';

import { jobPostsRoferoutes } from '../../routes/constants';
import { offersRoutes } from '../../../offers/routes/constants';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { StyledCreateJobPostPage } from './styles';

const CreateJobPostPage = () => {
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { authUser } = useAuth();
	const createJobPostMutation = useCreateJobPostMutation();

	const createJobPostHandler = async (values) => {
		const jobPostStartTime = moment(values.jobPostStartTime).format('YYYY-MM-DD HH:mm:ss');
		const jobPostEndTime = moment(values.jobPostEndTime).format('YYYY-MM-DD HH:mm:ss');

		try {
			setServerError('');

			let registryId = 1;

			const subdomain = window.location.hostname.split('.')[0];
			if (subdomain === 'findyourindependence') {
				// Render content for sub1
				registryId = 2;
			} else if (subdomain === 'philia') {
				// Render content for sub2
				registryId = 3;
			}

			const jobPost = await createJobPostMutation.mutateAsync({
				title: values.title,
				description: values.description,
				jobPostStartTime,
				jobPostEndTime,
				carePlanId: authUser.carePlan.id,
				registryId: registryId,
			});
			Notification(notifications.jobPosts.created);
			history.replace(`${offersRoutes.home}?job_post_id=${jobPost.id}`);
		} catch (error) {
			console.error('Create job post form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledCreateJobPostPage>
			<PageHeading>Create Job Post </PageHeading>

			<CreateJobPostForm
				initialValues={{
					title: '',
					description: '',
					jobPostStartTime: '',
					jobPostEndTime: '',
				}}
				createJobPostHandler={createJobPostHandler}
				serverError={serverError}
			/>
		</StyledCreateJobPostPage>
	);
};

export default CreateJobPostPage;
