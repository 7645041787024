import { useMutation } from '@tanstack/react-query';
import api from 'scripts/api';

import { TRAIT_MODEL } from '../models';

const editTrait = async ({
	trait_id,
	label,
	description,
	active,
	trait_type,
	section_id,
	meta_data,
	required,
	order_index,
	status,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
        editTrait ( 
          trait_id: ${trait_id},
          ${label ? `label: "${label}"` : ''}
          ${description ? `description: "${description}"` : ''}
          ${active ? `active: ${active}` : ''}
					${required ? `required: ${required}` : ''}
          ${trait_type ? `trait_type: "${trait_type}"` : ''}
          ${section_id ? `section_id: ${section_id}` : ''}
          ${meta_data ? `meta_data: ${JSON.stringify(meta_data)}` : ''}
          ${order_index ? `order_index: ${order_index}` : ''}
					${status ? `status: "${status}"` : ''}
        ) {
          ${TRAIT_MODEL}
        }
      }`,
		});

		return data.editTrait;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

const useEditTrait = () => {
	return useMutation({
		mutationKey: ['editTrait'],
		mutationFn: (values) => {
			return editTrait(values);
		},
	});
};

export default useEditTrait;
