import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import NotificationContainer from './components/NotificationsContainer';
import Loader from '../../../corev2/Loader';
import NavBar from './components/NavBar';
import Wrapper from './components/Wrapper';

import AppRoutes from './routes';

import { coreRoutes } from './routes/constants';
import { carePlanRoutes } from '../careplan/routes/constants';

import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';

const ClientAppContent = () => {
	const {
		isAuthenticated,
		appIsLoading,
		hasCompletedPlatformOnboarding,
		statusUpdate,
		hasDocumentsApproval,
		hasSetSchedule,
	} = useAuth();
	const history = useHistory();

	/**
	 *  Based on the auth context of the user, make all redirects here.
	 *  Please restrict yourself to making all redirects happen in the App.jsx file
	 */

	useEffect(() => {
		if (!appIsLoading) {
			if (isAuthenticated && !hasCompletedPlatformOnboarding) {
				return history.push(coreRoutes.carePlan);
			}

			if (isAuthenticated && !hasDocumentsApproval) {
				return history.push(coreRoutes.documents);
			}

			if (isAuthenticated && !hasSetSchedule) {
				return history.push(carePlanRoutes.schedule);
			}

			if (
				isAuthenticated &&
				hasCompletedPlatformOnboarding &&
				hasDocumentsApproval &&
				history.location.pathname.startsWith(coreRoutes.auth)
			) {
				return history.push(coreRoutes.jobPosts);
			}
		}
	}, [
		appIsLoading,
		isAuthenticated,
		hasCompletedPlatformOnboarding,
		hasDocumentsApproval,
		history,
	]);

	return (
		<>
			{appIsLoading && <Loader />}

			{!appIsLoading && (
				<>
					<NavBar
						isAuthenticated={isAuthenticated}
						hasCompletedPlatformOnboarding={hasCompletedPlatformOnboarding}
						statusUpdate={statusUpdate}
						hasDocumentsApproval={hasDocumentsApproval}
					/>
					<Wrapper>
						<AppRoutes />
						<NotificationContainer />
					</Wrapper>
				</>
			)}
		</>
	);
};

const ClientApp = () => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<ClientAppContent />
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default ClientApp;
