import React from 'react';
import { usePDF } from 'react-to-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import PageHeading from '../../components/PageHeading';
import TOS from '../../components/TOS';

import {
	StyledTOSPage,
	StyledPageHeadingContainer,
	StyledPdfContainer,
	StyledTosContainer,
} from './styles';

const TOSPage = () => {
	const { toPDF, targetRef } = usePDF({ filename: 'FYI_TOS.pdf' });

	const handlePrint = () => toPDF();

	return (
		<StyledTOSPage ref={targetRef}>
			<StyledPageHeadingContainer>
				<PageHeading> Terms of Service </PageHeading>

				<StyledPdfContainer onClick={handlePrint}>
					<FontAwesomeIcon icon={faFilePdf} />
				</StyledPdfContainer>
			</StyledPageHeadingContainer>

			<StyledTosContainer>
				<TOS />
			</StyledTosContainer>
		</StyledTOSPage>
	);
};

export default TOSPage;
